import React, { FC } from 'react';
import { Table, Space, Row, Col, Tooltip } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Link, useLocation, useMatch } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import moment from 'moment';
import _, { toInteger } from 'lodash';
import {
  DATE_FORMAT,
  PATHWAY_ELIGIBILITY_STATUS,
  CASE_STATE,
  ENGLISH_ELIGIBILITY_STATUS,
  SCREEN_TYPE,
  NUMBER_OF_RECORDS_PER_PAGE,
  SORT_FIELD,
} from '../../constants';
import { GetDisplayedColumns } from '../../../utils/helperFunctions';
import { useFeatureFlags } from '../../feature-flag-react-lite';
import { enableExperimentalFragmentVariables } from 'graphql-tag';
import { CaseSummaryType } from '../../../generated';

enableExperimentalFragmentVariables();

const CaseListTablePropsTypes = {
  tableLoading: PropTypes.bool,
  numberOfRecordsPerPage: PropTypes.number,
  initialViewList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      viewName: PropTypes.string,
      viewFilters: PropTypes.shape({
        caseStatus: PropTypes.arrayOf(PropTypes.string),
        pathwayEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
        pathway: PropTypes.arrayOf(PropTypes.string),
        englishEligibilityStatus: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
      displayColumns: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ),
  viewId: PropTypes.string,
  totalRecords: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onSortFieldChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export interface ComponentProps {
  initialTableData: CaseSummaryType;
  caseListColumnTitleUIState: any;
}

export type CaseListTableProps = InferProps<typeof CaseListTablePropsTypes> & ComponentProps;

const CaseListTable: FC<CaseListTableProps> = ({
  initialTableData,
  initialViewList,
  tableLoading,
  viewId,
  numberOfRecordsPerPage,
  totalRecords,
  pageNumber,
  onSortFieldChange,
  onPageChange,
  caseListColumnTitleUIState,
}) => {
  const { GetFeatureFlagByName } = useFeatureFlags();

  const location = useLocation();
  const chosenView = initialViewList?.find((view: any) => {
    return view._id === viewId;
  });

  var displayedColumnKeys: any = chosenView?.displayColumns;
  const displayedColumns = GetDisplayedColumns(
    SCREEN_TYPE.CASE_LIST,
    location.pathname,
    displayedColumnKeys
  );

  const renderTitle = (title: string, fieldName: string) => {
    var toolTipText = '';

    if (caseListColumnTitleUIState[fieldName].ASC === 1) {
      toolTipText = 'Click to sort descending';
    } else if (caseListColumnTitleUIState[fieldName].DESC === 1) {
      toolTipText = 'Click to cancel sorting';
    } else if (caseListColumnTitleUIState[fieldName].NOSORT === 1) {
      toolTipText = 'Click to sort ascending';
    }

    return (
      <div className="caseListTitle" onClick={() => onSortFieldChange(fieldName)}>
        <Tooltip title={toolTipText === '' ? 'Click to sort ascending' : toolTipText}>
          <Row>
            <Col span={20}>{title}</Col>
            <Col span={4}>
              <Row style={{ marginTop: 15 }}>
                <CaretUpOutlined
                  style={{
                    color: caseListColumnTitleUIState[fieldName].ASC === 0 ? '#bfbfbf' : '#3f4373',
                    fontSize: '11px',
                  }}
                />
              </Row>
              <Row>
                <CaretDownOutlined
                  style={{
                    color: caseListColumnTitleUIState[fieldName].DESC === 0 ? '#bfbfbf' : '#3f4373',
                    fontSize: '11px',
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Tooltip>
      </div>
    );
  };

  const displayedColumnKeysPostFeatureFlag = [
    {
      title: 'USMLE ID',
      dataIndex: 'usmleId',
      key: 'column-usmle-id',
    },
    {
      title: renderTitle('Days Since Last Update', 'LastUpdateDate'),
      dataIndex: 'updatedAt',
      key: 'column-days-since-last-update',
      align: 'center',
    },

    {
      title: renderTitle('Submission Date', 'SubmissionDate'),
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      align: 'center',
    },
    {
      title: renderTitle('Pathway', 'Pathway'),
      dataIndex: 'currentPathway',
      key: 'column-pathway',
      align: 'center',
    },
    {
      title: renderTitle('Case Status', 'CaseStatus'),
      dataIndex: 'caseState',
      key: 'column-case-status',
      align: 'center',
    },
    {
      title: renderTitle('General Eligibility', 'GeneralEligibility'),
      dataIndex: 'generalEligibilityStatus',
      key: 'column-general-eligibility',
      align: 'center',
    },
    {
      title: renderTitle('OET Case Status', 'OETCaseStatus'),
      dataIndex: 'englishEligibilityOutput',
      key: 'column-oet-case-status',
      align: 'center',
    },
    {
      title: renderTitle('Pathway Case Status', 'PathwaysCaseStatus'),
      dataIndex: 'pathwayEligibilityOutput',
      key: 'column-pathway-case-status',
      align: 'center',
    },
    {
      title: 'QA Rework',
      dataIndex: 'qaReworkTimestamp',
      key: 'column-qa-rework',
      align: 'center',
    },
    {
      title: renderTitle('Assigned To', 'AssignedTo'),
      dataIndex: 'eligibilityReviewAssigneeName',
      key: 'column-assigned-to',
      align: 'center',
    },
    {
      title: renderTitle('Document Last Upload Date', 'DocumentLastUpdateDate'),
      dataIndex: 'lastDocUploadTimestamp',
      key: 'column-last-doc-upload-timestamp',
      align: 'center',
    },
  ];

  const columnsDisplayed = () => {
    var displayedColumns: any = [];
    const actionColumn = {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={`${location.pathname}/case-detail/${record.usmleId}`}>View</Link>
        </Space>
      ),

      align: 'center',
    };
    if (
      !displayedColumnKeys ||
      displayedColumnKeys?.length === 0 ||
      displayedColumnKeys === displayedColumnKeysPostFeatureFlag
    ) {
      displayedColumns = [actionColumn, ...displayedColumnKeysPostFeatureFlag];
    } else {
      displayedColumnKeysPostFeatureFlag?.forEach((column: any) => {
        if (displayedColumnKeys?.includes(column.key)) {
          displayedColumns.push(column);
        }
      });
      displayedColumns = [actionColumn, ...displayedColumns];
    }
    return displayedColumns;
  };

  var tempInitialValues = initialTableData ? JSON.parse(JSON.stringify(initialTableData)) : [];
  if (typeof tempInitialValues === 'undefined') return <> No Data </>;
  tempInitialValues.forEach((item: any) => {
    item.createdAt = item.createdAt ? moment(item.createdAt).local().format(DATE_FORMAT) : '';

    item.updatedAt = item.updatedAt
      ? moment({ hours: 0 }).diff(moment(item.updatedAt), 'days')
      : '';

    item.submittedAt = item.submittedAt ? moment(item.submittedAt).local().format(DATE_FORMAT) : '';

    //translate case state
    item.caseState = _.find(CASE_STATE, {
      key: item.caseState,
    })?.value;

    //translate pathway case status
    item.pathwayEligibilityOutput = _.find(PATHWAY_ELIGIBILITY_STATUS, {
      key: item.pathwayEligibilityOutput,
    })?.value;

    //translate oet status
    item.englishEligibilityOutput = _.find(ENGLISH_ELIGIBILITY_STATUS, {
      key: item.englishEligibilityOutput,
    })?.value;

    item.qaReworkTimestamp = item.qaReworkTimestamp
      ? moment(item.qaReworkTimestamp).local().format('MM/DD/YYYY')
      : '';

    item.lastDocUploadTimestamp = item.lastDocUploadTimestamp
      ? moment(item.lastDocUploadTimestamp).local().format('MM/DD/YYYY')
      : '';

    //translate general eligibility
    // item.generalEligibilityOutput = _.find(GENERAL_ELIGIBILITY_STATUS, {
    //   key: item.generalEligibilityOutput,
    // })?.value;
  });

  return (
    <>
      <Table
        pagination={{
          showSizeChanger: false,
          showTotal: (total: number) => {
            return GetFeatureFlagByName('ShowCaseCount') === 'true' ? `Total ${total} items` : ``;
          },
          pageSize: numberOfRecordsPerPage ?? NUMBER_OF_RECORDS_PER_PAGE[0].value,
          current: pageNumber,
          total: totalRecords,
          position: ['topRight', 'bottomRight'],
          onChange: (page: number) => {
            onPageChange(page);
          },
        }}
        scroll={{ x: '100%' }}
        bordered
        size="middle"
        loading={tableLoading ?? false}
        className="ecfmg-small-table"
        dataSource={tempInitialValues}
        columns={
          GetFeatureFlagByName('QAReworkFlag') === 'true' ? columnsDisplayed() : displayedColumns
        }
      ></Table>
    </>
  );
};

export default {
  Component: CaseListTable,
};
