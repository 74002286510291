import React, { FC, useState } from 'react';
import { Table } from 'antd';
import Column from 'antd/lib/table/Column';
import PropTypes, { InferProps } from 'prop-types';

export const PricingDistributionRuleTableType = {
  selectedRowKey: PropTypes.number,
  onRowSelected: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      configName: PropTypes.string.isRequired, //rule name
      formattedDateFrom: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired,
      isCurrent: PropTypes.string.isRequired,
    })
  ),
};

export type PricingDistributionRuleTableProps = InferProps<typeof PricingDistributionRuleTableType>;

const PricingDistributionRuleTable: FC<PricingDistributionRuleTableProps> = ({
  selectedRowKey,
  onRowSelected,
  data,
}) => {
  //console.log(data);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  console.log(selectedRowKey);
  const selectRow = async (record: any) => {
    onRowSelected(record); //callback - execute on the parent of this component
    setSelectedRowKeys([record.key]);
    console.log([record.key]);
  };

  const rowSelection = {
    type: 'radio' as 'checkbox' | 'radio' | undefined,
    hideSelectAll: true,
    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [selectedRowKeys],
  };
  return (
    <>
      <Table
        loading={data ? false : true}
        rowClassName="rule-list-row"
        rowSelection={rowSelection}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              selectRow(record);
            }, // click row
            onChange: (event) => {
              selectRow(record);
            },
          };
        }}
        dataSource={(data as object[]) ?? []}
        scroll={{ y: 240 }}
        size="middle"
        className="ecfmg-small-table"
        style={{ width: '99%' }}
      >
        <Column title="Effective Date" dataIndex="formattedDateFrom" />
        <Column title="Rule Name" dataIndex="configName" />
        <Column title="Version" dataIndex="version" />
        {/* <Column dataIndex="isCurrent" /> */}
      </Table>
    </>
  );
};

export default PricingDistributionRuleTable;

PricingDistributionRuleTable.defaultProps = {
  onRowSelected: () => {},
  data: [],
  selectedRowKey: undefined,
};
