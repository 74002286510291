import React, { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { Descriptions, Row, Col } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../constants';
import { toInteger } from 'lodash';
import { useFeatureFlags } from '../../feature-flag-react-lite';
import { CaseResponseType } from '../../../generated';


const CaseOverviewSummaryPropsTypes = {
  refetch: PropTypes.func,
};

export interface ComponentProps {
  initialValues: CaseResponseType;
}

export type CaseOverviewSummaryProps = InferProps<typeof CaseOverviewSummaryPropsTypes> &
  ComponentProps;

type paramType = {
  caseId: string;
};
const CaseOverview: FC<CaseOverviewSummaryProps> = ({
  initialValues,
  refetch,
}) => {
  let { caseId } = useParams<paramType>();
  console.log(`param:${caseId}`);
  const { GetFeatureFlagByName } = useFeatureFlags();
  const [usmleId, setUsmleId] = useState<string | undefined>(
    initialValues?.caseSummary?.usmleId ?? undefined
  );
  const [dateSubmitted, setDateSubmitted] = useState<Date | undefined>(
    initialValues?.caseSummary?.submittedAt
      ? moment(initialValues.caseSummary.submittedAt).toDate()
      : undefined
    //Update this when we actually get a date
  );
  const [lastActivity, setLastActivity] = useState<Date | undefined>(
    moment(initialValues?.caseSummary?.updatedAt).toDate() ?? undefined
    //Calculate this when we actually get a date
  );
  const [isEligible, setIsEligible] = useState<boolean | undefined>(
    initialValues?.caseSummary?.isEligible ?? undefined
  );
  const [lastName, setLastName] = useState<string | undefined>(
    initialValues?.caseSummary?.lastName ?? undefined
  );
  const [restOfName, setRestOfName] = useState<string | undefined>(
    initialValues?.caseSummary?.firstName ?? undefined
  );
  const [caseOutput, setCaseOuput] = useState<string | undefined>(
    initialValues?.caseSummary?.output ?? undefined
  );
  const [qaReworkTimestamp, setQAReworkTimestamp] = useState<any>(
    initialValues?.caseSummary?.qaReworkTimestamp
      ? moment(initialValues?.caseSummary?.qaReworkTimestamp).toDate()
      : undefined
  );
  const [generalEligibilityStatus, setGeneralEligibilityStatus] = useState<string | undefined>(
    initialValues?.caseSummary?.generalEligibilityStatus ?? undefined
  );
  const [generalEligibilityOutputReason, setGeneralEligibilityOutputReason] = useState<any>(
    initialValues?.caseSummary?.generalEligibilityOutputReason
  );
  const [caseState, setCaseState] = useState<string | undefined>(
    initialValues?.caseSummary?.caseState ?? undefined
  );

  useEffect(() => {
    if (initialValues?.caseSummary) {
      setCaseState(initialValues?.caseSummary?.caseState!);
    }
  });

  const rearrangedSummary = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="USMLE ID">{usmleId}</Descriptions.Item>
              <Descriptions.Item label="Name">{`${lastName}, ${restOfName}`}</Descriptions.Item>
            </Descriptions>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Case Status">{caseState}</Descriptions.Item>

              {caseState === 'ApplicationCompleted' ? (
                <Descriptions.Item label="Case Decision">{caseOutput}</Descriptions.Item>
              ) : null}

              {qaReworkTimestamp ? (
                <Descriptions.Item label="QA Rework">
                  {moment(toInteger(qaReworkTimestamp)).format(DATE_FORMAT)}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Submission Date">
                {dateSubmitted ? moment(dateSubmitted).format(DATE_FORMAT) : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Last Activity">
                {lastActivity
                  ? moment({ hours: 0 }).diff(lastActivity, 'days') + ' days ago'
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Last Oracle Refresh Time">
                {initialValues?.caseSummary?.updatedAt
                  ? moment(initialValues?.caseSummary.updatedAt).format(DATE_FORMAT)
                  : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Eligibility Check">
                {isEligible ? 'Pass' : 'Fail'}
              </Descriptions.Item>

              {caseOutput && caseState === 'ApplicationComplete' ? (
                <Descriptions.Item label="Case Decision">{caseOutput}</Descriptions.Item>
              ) : null}

              <Descriptions.Item label="General Eligibility">
                {generalEligibilityStatus}
                {generalEligibilityOutputReason ? ` - ${generalEligibilityOutputReason}` : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {GetFeatureFlagByName('QAReworkFlag') === 'true' ? (
        rearrangedSummary()
      ) : (
        <Row>
          <Col span={24}>
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="USMLE ID">{usmleId}</Descriptions.Item>
              <Descriptions.Item label="Submission Date">
                {dateSubmitted ? moment(dateSubmitted).format(DATE_FORMAT) : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Last Activity">
                {lastActivity
                  ? moment({ hours: 0 }).diff(lastActivity, 'days') + ' days ago'
                  : 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Eligibility Check">
                {isEligible ? 'Pass' : 'Fail'}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{`${lastName}, ${restOfName}`}</Descriptions.Item>
              <Descriptions.Item label="Case Status">{caseState}</Descriptions.Item>
              {caseOutput ? (
                <Descriptions.Item label="Case Output">{caseOutput}</Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Last Oracle Refresh Time">
                {initialValues?.caseSummary?.updatedAt
                  ? moment(initialValues?.caseSummary.updatedAt).format(DATE_FORMAT)
                  : 'N/A'}
              </Descriptions.Item>

              <Descriptions.Item label="General Eligibility">
                {generalEligibilityStatus}
              </Descriptions.Item>
              {generalEligibilityOutputReason ? (
                <Descriptions.Item label="General Eligibility Output Reason">
                  {generalEligibilityOutputReason}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
            {/*caseOutputReason ?
          <Collapse>
          <Collapse.Panel header="Case Output Reasons:" key={1}>
          {caseOutputReason.map((reason:string) => {
            return <li>{reason}</li>;
          })}
        </Collapse.Panel>
          </Collapse> : null*/}
          </Col>
        </Row>
      )}
    </>
  );
};

export default {
  Component: CaseOverview,
};
