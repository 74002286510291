import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TransactionHistoryDetails from './transaction-history-details';
import RuleDetails, { RuleDetailsProps, RULE_DETAIL_DISPLAY_MODE } from './rule-details';
import { Row, Col, Button, message, Modal, Spin, Typography } from 'antd';
import { useQuery, gql, useMutation } from '@apollo/client';
import PricingDistributionRuleTable from './pricing-distribution-rule-table';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import _ from 'lodash';
import { glDistributionConfig } from '../schema/casePortal-glDistributionConfig';
import {
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateDocument,
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateDocument,
  CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditDocument,
  CaseMgmtFinanceSettingsLayoutCasePortalDocument,
} from '../../generated';
const { Title } = Typography;

//Store selected row temporarily.
//Used to handle situation when users are adding a new rule,
//and accidentally click on a row in the middle of the editting
var tempSelectedRecord: any;

export interface ComponentProps {
  casePortal: {
    glDistributionConfig: glDistributionConfig;
  };
}

const FinanceSettingsLayout: FC<any> = () => {
  //for rule list table
  const [ruleList, setRuleList] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState<any | undefined>(undefined);
  const [selectedRowKey, setSelectedRowKey] = useState(undefined);

  //queries + mutations
  const {
    loading,
    error,
    data: rulesData,
  } = useQuery<ComponentProps>(CaseMgmtFinanceSettingsLayoutCasePortalDocument);
  const [createGLDistributionConfig] = useMutation(
    CaseMgmtFinanceSettingsLayoutGlDistributionConfig_CreateDocument,
    {
      refetchQueries: [{ query: CaseMgmtFinanceSettingsLayoutCasePortalDocument }],
      awaitRefetchQueries: true,
    }
  );
  const [editGLDistributionConfig] = useMutation(
    CaseMgmtFinanceSettingsLayoutGlDistributionConfig_EditDocument,
    {
      refetchQueries: [{ query: CaseMgmtFinanceSettingsLayoutCasePortalDocument }],
      awaitRefetchQueries: true,
    }
  );
  const [deactivateGLDistributionConfig] = useMutation(
    CaseMgmtFinanceSettingsLayoutGlDistributionConfig_DeactivateDocument,
    {
      refetchQueries: [{ query: CaseMgmtFinanceSettingsLayoutCasePortalDocument }],
      awaitRefetchQueries: true,
    }
  );

  //for rule detail area
  const [ruleDetailDisplayMode, setRuleDetailDisplayMode] = useState<string>(
    RULE_DETAIL_DISPLAY_MODE.NONE
  ); //manipuate the display of Rule detail area

  //for Modal
  const [modalVisible, setModalVisible] = useState(false);

  const convertDate = (date: string) => {
    var tempDate = new Date(date);
    var parsedDate = '';
    parsedDate += (tempDate.getMonth() + 1).toString().padStart(2, '0'); // Month
    parsedDate += '-' + tempDate.getDate().toString().padStart(2, '0'); // Day
    parsedDate += '-' + tempDate.getFullYear().toString(); // Year

    return parsedDate;
  };

  useEffect(() => {
    //initialization after getting data from server

    if (rulesData) {
      const tempRuleList = JSON.parse(JSON.stringify(rulesData.casePortal.glDistributionConfig));
      tempRuleList.map((rule: any) => {
        const formattedDateFrom = rule.dateFrom
          ? moment(rule.dateFrom).local().format('YYYY-MM-DD')
          : undefined;
        rule.formattedDateFrom = formattedDateFrom;
        rule.key = rule._id;
      });

      setRuleList(tempRuleList);
    }
  }, [rulesData]);

  const onModalOKClick = async () => {
    setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.EDIT);
    setSelectedRecord(tempSelectedRecord);
    setSelectedRowKey(tempSelectedRecord._id);
    setModalVisible(false);
  };

  const onModalCancelClick = async () => {
    setModalVisible(false);
  };

  const onRowSelected = async (record: any) => {
    //console.log(`SelectedREcord: ${record}`);
    if (ruleDetailDisplayMode === RULE_DETAIL_DISPLAY_MODE.ADD_NEW) {
      tempSelectedRecord = record;
      setModalVisible(true);
    } else {
      setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.EDIT);
      setSelectedRecord(record);
      setSelectedRowKey(record!._id);
    }
  };

  const onCancelClick = async () => {
    setSelectedRecord(undefined);
    setSelectedRowKey(undefined); //to clear selection on table
    setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.NONE);
  };

  const onAddNewClick = async () => {
    setSelectedRowKey(undefined);
    const emptyRecord = {
      _id: undefined,
      configName: undefined,
      dateFrom: undefined,
      version: 1,
      glAccountCashControl: undefined,
      glAccountUnearned: undefined,
      glAccountEarned: undefined,
      glAccountRefundControl: undefined,
      standardCaseFee: undefined,
      standardCaseInitialIntake: undefined,
      exceptionCaseFee: undefined,
      exceptionCaseInitialIntake: undefined,
    };
    setSelectedRecord(emptyRecord);
    setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.ADD_NEW);
  };
  const onSaveClick = async (record: any) => {
    console.log(record);
    if (ruleDetailDisplayMode === RULE_DETAIL_DISPLAY_MODE.ADD_NEW) {
      //mutation create
      await createGLDistributionConfig({
        variables: {
          configName: record.configName,
          dateFrom: record.dateFrom,
          glAccountCashControl: record.glAccountCashControl,
          glAccountUnearned: record.glAccountUnearned,
          glAccountEarned: record.glAccountEarned,
          glAccountRefundControl: record.glAccountRefundControl,
          standardCaseFee: record.standardCaseFee,
          standardCaseInitialIntake: record.standardCaseInitialIntake,
          exceptionCaseFee: record.exceptionCaseFee,
          exceptionCaseInitialIntake: record.exceptionCaseInitialIntake,
        },
      }).then((data) => {
        message.success('Create new rule successfully!');
        setSelectedRecord(undefined);
        setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.NONE);
        window.scrollTo(0, 0);
      });
    } else {
      await editGLDistributionConfig({
        variables: {
          _id: record._id,
          version: record.version,
          input: {
            configName: record.configName,
          },
        },
      }).then((data: any) => {
        message.success('Update successfully!');
        setSelectedRecord(undefined);
        setSelectedRowKey(undefined); //to clear selection on table
        setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.NONE);
        window.scrollTo(0, 0);
      });
    }
  };

  const onDeactivateClick = async (_id: any, version: any) => {
    //TODO: disable current rule
    await deactivateGLDistributionConfig({
      variables: {
        _id: _id,
        version: version,
      },
    }).then(
      (data: any) => {
        message.success('Rule deactivated!');

        setSelectedRecord(undefined);
        setRuleDetailDisplayMode(RULE_DETAIL_DISPLAY_MODE.NONE);
        window.scrollTo(0, 0);
      },
      (error: any) => {
        message.error(error.message);
      }
    );
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', margin: '80px 40px' }}>
        <Spin size="large" />
        <Title level={2}>Loading Finance Settings</Title>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Finance Settings</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <Title level={3}>Finance Details</Title>
          <Title level={4}>Pricing / Distribution Rules</Title>
        </Col>
      </Row>
      <Row>
        <Col span={23.9}>
          <PricingDistributionRuleTable
            selectedRowKey={selectedRowKey}
            onRowSelected={(record) => onRowSelected(record)}
            data={ruleList}
          />
          <div style={{ minWidth: '100%', textAlign: 'right' }}>
            <Button onClick={() => onAddNewClick()}>Add New</Button>
          </div>
          <br />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <RuleDetails
            onCancelCallBack={() => onCancelClick()}
            onSaveCallBack={(record) => onSaveClick(record)}
            onDeactivateCallBack={(_id, version) => onDeactivateClick(_id, version)}
            initialValues={selectedRecord}
            displayMode={ruleDetailDisplayMode}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <hr style={{ borderTop: '1px black solid', margin: '25px 0' }} />
          <Title level={4}>Transaction History Details</Title>
        </Col>
      </Row>
      <TransactionHistoryDetails />

      <Modal
        title="Display Rule Detail"
        visible={modalVisible}
        onOk={onModalOKClick}
        onCancel={onModalCancelClick}
        okText="Yes"
        cancelText="No"
      >
        <div>You are adding a new rule.</div>
        <div>Displaying rule details will discard all the changes you are making.</div>
        <br />
        <p>Are you sure you want to do that?</p>
      </Modal>
    </>
  );
};

export default FinanceSettingsLayout;
