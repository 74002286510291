import { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CaseSummaryType,
  C_ApplicantType,
  UiPageConfigType,
  UiPortalConfigType,
} from '../../../../../generated';

import { COMPONENT_NAME, PAGE_NAME } from '../../../../utils/version-mappings';
import Pathway6DocumentReviewChecklist from './checklist-versions/pathway6-document-review-checklist';
import Pathway6DocumentReviewChecklistV2 from './checklist-versions/pathway6-document-review-checklist-v2';

const ComponentPropTypes = {
  checklist: PropTypes.shape({
    minicexComplete: PropTypes.string.isRequired,
    minicexScoreAcceptable: PropTypes.string.isRequired,
  }).isRequired,
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
  areAllMiniCEXComplete: PropTypes.bool.isRequired,
};

export interface IProps {
  versionConfig: UiPortalConfigType;
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes & IProps>;

const Pathway6DocumentReviewChecklistFactory: FC<ComponentProps> = ({
  versionConfig,
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  checklistStatus,
  applicant,
  redirect,
  areAllMiniCEXComplete
}) => {
  const getPathway6Checklist = () => {
    return versionConfig?.config?.pages.map((page: UiPageConfigType) => {
      if (page?.pageName === PAGE_NAME.CASE_CHECKLIST) {
        return page?.components?.map((component) => {
          if (component?.componentName === COMPONENT_NAME.PATHWAY6_CHECKLIST) {
            switch (component.version) {
              case 1:
                return (
                  <Pathway6DocumentReviewChecklist
                    caseSummary={caseSummary}
                    checklist={checklist}
                    checklistState={checklistState}
                    recordId={recordId}
                    parentDataVersion={parentDataVersion}
                    onUpdateDataVersion={(dataVersion: number) => {
                      onUpdateDataVersion(dataVersion);
                    }}
                    applicant={applicant}
                    redirect={(url: string) => {
                      redirect(url);
                    }}
                    checklistStatus={checklistStatus}
                    areAllMiniCEXComplete={areAllMiniCEXComplete}
                  />
                );
                case 2:
                default:
                  return (
                    <Pathway6DocumentReviewChecklistV2
                      caseSummary={caseSummary}
                      checklist={checklist}
                      checklistState={checklistState}
                      recordId={recordId}
                      parentDataVersion={parentDataVersion}
                      onUpdateDataVersion={(dataVersion: number) => {
                        onUpdateDataVersion(dataVersion);
                      }}
                      applicant={applicant}
                      redirect={(url: string) => {
                        redirect(url);
                      }}
                      checklistStatus={checklistStatus}
                      areAllMiniCEXComplete={areAllMiniCEXComplete}
                    />
                  );
            }
          }
        });
      }
    });
  };

  return <div>{getPathway6Checklist()}</div>;
};

export default Pathway6DocumentReviewChecklistFactory;
