import React, { FC, useEffect, useState } from 'react';
import { Form, Space, Button, Row, Col, Select, Popconfirm, message, Typography } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import { SendOutlined, CheckOutlined } from '@ant-design/icons';
import { PATHWAY6_SUBCASE_STATUSES } from '../../../../../constants';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  CaseMgmtPathway6DocumentReviewChecklistReviewDocument,
  CaseSummaryType,
  C_ApplicantType,
} from '../../../../../../generated';
const { Title } = Typography;

const { Option } = Select;

const Pathway6DocumentReviewChecklistPropsTypes = {
  checklist: PropTypes.shape({
    minicexComplete: PropTypes.string.isRequired,
    minicexScoreAcceptable: PropTypes.string.isRequired,
  }).isRequired,
  checklistState: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  parentDataVersion: PropTypes.number,
  onUpdateDataVersion: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  checklistStatus: PropTypes.string.isRequired,
  areAllMiniCEXComplete: PropTypes.bool.isRequired,
};

export interface ComponentProps {
  caseSummary: CaseSummaryType;
  applicant: C_ApplicantType;
}

export type Pathway6DocumentReviewChecklistProps = InferProps<
  typeof Pathway6DocumentReviewChecklistPropsTypes
> &
  ComponentProps;

const Pathway6DocumentReviewChecklistV2: FC<Pathway6DocumentReviewChecklistProps> = ({
  checklist,
  caseSummary,
  checklistState,
  recordId,
  parentDataVersion,
  onUpdateDataVersion,
  applicant,
  redirect,
  checklistStatus,
  areAllMiniCEXComplete,
}) => {
  const navigate = useNavigate();
  //states
  const [licenseStatus, setLicenseStatus] = useState<string | undefined>(undefined);
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [form] = Form.useForm();
  const [setPathway6Checklist, { loading: setReviewLoading }] = useMutation(
    CaseMgmtPathway6DocumentReviewChecklistReviewDocument
  );
  const [miniCEXComplete, setMiniCEXComplete] = useState<string | undefined>(undefined);
  const [miniCEXScoreAcceptable, setMiniCEXScoreAcceptable] =
    useState<string | undefined>(undefined);
  const [checklistDisabled, setChecklistDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (checklistState && typeof licenseStatus == 'undefined') {
      setLicenseStatus(checklistState);
      form.setFieldsValue({
        licenseStatus: checklistState,
      });
    }
    if (parentDataVersion) {
      setDataVersion(parentDataVersion);
    }
    if (checklist) {
      if (typeof miniCEXComplete == 'undefined') {
        setMiniCEXComplete(checklist?.minicexComplete);
        form.setFieldsValue({
          miniCEXComplete: checklist.minicexComplete,
        });
      }
      if (typeof miniCEXScoreAcceptable == 'undefined') {
        setMiniCEXScoreAcceptable(checklist?.minicexScoreAcceptable);
        form.setFieldsValue({
          miniCEXScoreAcceptable: checklist.minicexScoreAcceptable,
        });
      }
    }
  });

  useEffect(() => {
    //If all 6 mini-CEXs are finish, check if the checklist should be enabled
    if (areAllMiniCEXComplete) {
      const checklistDisabled: boolean =
        checklistStatus?.toUpperCase() === 'APPROVED' ||
        checklistStatus?.toUpperCase() === 'REJECTED' ||
        caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED' ||
        caseSummary?.eligibilityReviewAssigneeId !== sessionStorage.getItem('userIdToken');

      setChecklistDisabled(checklistDisabled);
    }
  }, [checklistStatus, caseSummary]);

  //helper functions
  const selectOptions = () => {
    return (
      <>
        <Option value=""> </Option>
        <Option value="No">No</Option>
        <Option value="Yes">Yes</Option>
        <Option value="N/A">N/A</Option>
      </>
    );
  };

  const updateCheckList = async (isComplete: boolean) => {
    const savedData = {
      minicexComplete: miniCEXComplete || '',
      minicexScoreAcceptable: miniCEXScoreAcceptable || '',
    };

    var tempRecordId: any = recordId;
    if (tempRecordId?.toUpperCase() === 'OveridePath'.toUpperCase()) {
      tempRecordId = null;
    }
    await setPathway6Checklist({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion!,
        recordId: tempRecordId,
        isComplete: isComplete,
        checklistResponse: savedData,
        checklistState: licenseStatus || '',
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_pathway6_setReview?.caseSummary;
        setDataVersion(casePortalResponse?.dataVersion);
        onUpdateDataVersion(casePortalResponse?.dataVersion);
        redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onFinish = () => {
    updateCheckList(true);
  };
  const onSaveAndReturnClicked = () => {
    updateCheckList(false);
  };
  const onLicenceStatusChanged = (value: any) => {
    setLicenseStatus(value);
  };
  const onCancelClicked = () => {
    redirect(`/case-management/case-detail/${caseSummary?.usmleId}`);
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} size="small">
        <Row>
          <Col span={24}>
            <div style={{ border: '1px black solid', padding: '15px' }}>
              <Title level={3}>Pathway 6 Review</Title>
              <hr style={{ borderTop: '1px black solid' }} />
              <Row justify="end">
                <Col>
                  <Button shape="round" icon={<SendOutlined />} size={'small'}>
                    <a href={`mailto:${applicant?.email}?subject=ECFMG%20Pathway%20Application`}>
                      {' Contact Applicant'}
                    </a>
                  </Button>
                </Col>
              </Row>

              <Title level={4}>Status</Title>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="licenseStatus"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a license status',
                      },
                    ]}
                  >
                    <Select
                      disabled={checklistDisabled}
                      value={licenseStatus}
                      key={licenseStatus}
                      onChange={(value) => onLicenceStatusChanged(value)}
                    >
                      {PATHWAY6_SUBCASE_STATUSES.map((s) => (
                        <Option value={s.key} key={s.key}>
                          {s.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Title level={4}>Evaluation</Title>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="miniCEXComplete"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                    initialValue={miniCEXComplete}
                  >
                    <Select
                      disabled={checklistDisabled}
                      onChange={(value: any) => {
                        setMiniCEXComplete(value);
                      }}
                      value={miniCEXComplete}
                      style={{ width: '70px' }}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={2}>Mini-CEX Complete</Col>
              </Row>

              <Row>
                <Col span={4}>
                  <Form.Item
                    name="miniCEXScoreAcceptable"
                    rules={[
                      {
                        required: true,
                        message: <div style={{ width: '200px' }}>Please select an answer</div>,
                      },
                    ]}
                  >
                    <Select
                      disabled={checklistDisabled}
                      value={miniCEXScoreAcceptable}
                      onChange={(value: any) => setMiniCEXScoreAcceptable(value)}
                      style={{ width: '70px' }}
                    >
                      {selectOptions()}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={2}>Mini-CEX Score Acceptable</Col>
              </Row>

              <Row justify="end">
                <Col>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={form.submit}
                    disabled={checklistDisabled}
                  >
                    <Button
                      disabled={checklistDisabled}
                      type="primary"
                      shape="round"
                      icon={<CheckOutlined />}
                      size={'small'}
                    >
                      Complete Review
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
              <br />
              <Row justify="end">
                <Col>
                  <Space>
                    <Button
                      disabled={checklistDisabled}
                      shape="round"
                      onClick={() => {
                        onCancelClicked();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={checklistDisabled}
                      shape="round"
                      type="primary"
                      onClick={() => {
                        onSaveAndReturnClicked();
                      }}
                      loading={setReviewLoading}
                    >
                      Save and Return
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Pathway6DocumentReviewChecklistV2;
