import { FC, useState, useEffect } from 'react';
import { Button, Table, Modal, Col, Row, message, Tabs } from 'antd';
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { InferProps } from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  ADMIN_ALLOWED_ACTIONS,
  PATHWAY6_DATE_FORMAT,
  CEX_STATUS,
  getPhysicianName,
} from '../../../../constants';
import Pathway6ReviewPhysicianDetails from './modals/pathway6-review-physician-details';
import Pathway6RejectMiniCEXRequest from './modals/pathway6-reject-miniCEX-request';
import Pathway6MiniCEXStatusHistory from './modals/pathway6-miniCEX-status-history';
import moment from 'moment';
import Pathway6CancelMinicex from './pathway6-cancel-minicex';
import {
  CaseMgmtPathway6MiniCexActiveTableRejectDocument,
  MiniCexResponseType,
  PhysicianResponseType,
  MiniCexSummaryType,
} from '../../../../../generated';
import { ERROR_MESSAGES_MAP } from '../../../../../utils/error-messages';
import { getMiniCEXAttestationPDF, getMiniCEXPDF } from './pathway6-pdf-helpers';
import ReviewRejectedMiniCEXFactory from './pathway6-review-rejected-miniCEX-factory';
const { TabPane } = Tabs;

const Pathway6MiniCEXActiveTablePropTypes = {};

export interface ComponentProps {
  minicexList: MiniCexResponseType[];
  physicianByMiniCEX: PhysicianResponseType[];
}

export type Pathway6MiniCEXActiveTableProps = InferProps<
  typeof Pathway6MiniCEXActiveTablePropTypes
> &
  ComponentProps;

const Pathway6MiniCEXActiveTable: FC<Pathway6MiniCEXActiveTableProps> = ({
  minicexList,
  physicianByMiniCEX,
}) => {
  const [isReviewPhysicianDetailsModalVisible, setIsReviewPhysicianDetailsModalVisible] =
    useState(false);
  const [isRejectMiniCEXVisible, setIsRejectMiniCEXVisible] = useState(false);
  const [isStatusHistoryVisible, setIsStatusHistoryVisible] = useState(false);
  const [isReadOnlyMiniCEXVisible, setIsReadOnlyMiniCEXVisible] = useState(false);
  const [summaryList, setSummaryList] = useState<MiniCexSummaryType[]>();
  const [currentRecord, setCurrentRecord] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<any>();
  const [currentPhysicianData, setCurrentPhysicianData] = useState<any>();
  const [readOnlyMiniCEXData, setReadOnlyMiniCEXData] = useState<MiniCexResponseType>();
  const [isReviewRejectedMiniCEXVisible, setIsReviewRejectedMiniCEXVisible] = useState(false);
  const [rejectMiniCEX, { loading: rejectMiniCEXLoading }] = useMutation(
    CaseMgmtPathway6MiniCexActiveTableRejectDocument
  );

  useEffect(() => {
    //Get summary from minicexList
    setSummaryList(
      minicexList?.map((item: MiniCexResponseType) => ({
        ...item.summary,
      }))
    );
  }, [minicexList]);

  useEffect(() => {
    let tempPhysicianData: any = physicianByMiniCEX?.find(
      (item) => item?.email === currentRecord?.physicianEmail
    );
    console.log('tempPhysicianData', tempPhysicianData);
    setCurrentPhysicianData(
      physicianByMiniCEX?.find((item) => item?.email === currentRecord?.physicianEmail)
    );

    console.log('currentRecord', currentRecord);
  }, [currentRecord]);

  const onRejectClicked = (encounterId: string) => {
    setIsRejectMiniCEXVisible(true);
  };

  const getPhysicianNameByEmail = (email: any) => {
    var currentPhysicianRecord: any = physicianByMiniCEX?.find((item) => item?.email === email);

    return getPhysicianName(
      currentPhysicianRecord?.profile?.lastName,
      currentPhysicianRecord?.profile?.restOfName
    );
  };

  const onPhysicianNameClicked = () => {
    //TODO: argument would be changed to email instead
    // TODO: call mutation to get physician details
    setIsReviewPhysicianDetailsModalVisible(true);
  };

  const onRejectMiniCEX = async () => {
    await rejectMiniCEX({
      variables: {
        encounterId: currentRecord.encounterId,
        minicexDataVersion: currentRecord.dataVersion,
      },
    })
      .then((result) => {
        updateSummaryRecord(result.data?.CasePortal_MiniCEX_reject?.summary);
      })
      .catch((error) => {
        const graphQLErrorReference = ERROR_MESSAGES_MAP.find(
          (err: any) => err.key === error?.graphQLErrors?.[0]?.message
        );
        message.error(
          `${
            graphQLErrorReference?.value
              ? graphQLErrorReference?.value
              : error?.graphQLErrors?.[0]?.message
          } - Reference ID : ${error.graphQLErrors[0].extensions.referenceId}`
        );
      });

    setIsRejectMiniCEXVisible(false);
  };

  const updateSummaryRecord = (record: any) => {
    if (summaryList) {
      var tempSummaryList = [...summaryList];
      tempSummaryList[currentIndex] = record;

      setSummaryList(tempSummaryList);
    }
  };

  const renderButtons = (record: MiniCexSummaryType, index: any) => {
    return record?.allowedActions?.map((action: any) => {
      return (
        <div key={action}>
          {action === ADMIN_ALLOWED_ACTIONS.VIEW ? (
            <>
              {/* <Button
                key={record?.encounterId!}
                onClick={() => {
                  setCurrentRecord(record);
                  setIsAttestationPDFVisible(true);
                }}
                type="primary"
                style={{ width: '100%', background: 'green' }}
              >
                View Attestation
              </Button> */}
              <Button
                key={record?.encounterId!}
                onClick={() => {
                  setCurrentRecord(record);
                  setReadOnlyMiniCEXData(minicexList[index]);
                  if (record.rejectionReason) {
                    setIsReviewRejectedMiniCEXVisible(true);
                  } else {
                    setIsReadOnlyMiniCEXVisible(true);
                  }
                }}
                type="primary"
                style={{ fontSize: '14px', width: '100px', marginLeft: '5px' }}
                icon={<SearchOutlined />}
              >
                View
              </Button>
            </>
          ) : null}
          {action === ADMIN_ALLOWED_ACTIONS.REJECT ? (
            <Button
              key={record?.encounterId!}
              style={{ fontSize: '14px', width: '100px', marginLeft: '5px' }}
              type="primary"
              danger
              onClick={() => {
                setCurrentRecord(record);
                setCurrentIndex(index);
                onRejectClicked(record?.encounterId!);
              }}
              icon={<CloseCircleOutlined />}
            >
              Reject
            </Button>
          ) : null}
          {action === ADMIN_ALLOWED_ACTIONS.CANCEL ? (
            <Pathway6CancelMinicex.Component key={record?.encounterId!} minicexSummary={record} />
          ) : null}
        </div>
      );
    });
  };

  const getStatus = (obj: any, key: any) => {
    return obj[key];
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'encounterId',
      key: 'encounterId',
      render: (text: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>{record.encounterId}</div>
      ),
    },
    {
      title: 'Name from Physician',
      key: 'physicianNameProvidedByPhysician',
      render: (physicianNameProvidedByPhysician: any, record: any) => (
        <div>
          {
            <a
              style={{ marginLeft: '5px' }}
              onClick={() => {
                setCurrentRecord(record);
                onPhysicianNameClicked();
              }}
            >
              {' '}
              {getPhysicianNameByEmail(record.physicianEmail)}{' '}
            </a>
          }
        </div>
      ),
    },
    {
      title: 'Date Requested',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any, record: MiniCexSummaryType) => (
        <div style={{ marginLeft: '5px' }}>
          {!!record.createdAt ? moment(record.createdAt).utc().format(PATHWAY6_DATE_FORMAT) : ''}
        </div>
      ),
    },
    {
      title: 'Action Due',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      render: (expiresAt: any, record: any) => (
        <div style={{ marginLeft: '5px' }}>
          {!!record.expiresAt ? moment(record.expiresAt).utc().format(PATHWAY6_DATE_FORMAT) : ''}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (status: any, record: any) => (
        <>
          {record.state !== undefined ? (
            <Row wrap={false}>
              <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '5px'}}>
                <InfoCircleOutlined
                  style={{ fontSize: '15px' }}
                  onClick={() => {
                    setCurrentRecord(record);
                    setIsStatusHistoryVisible(true);
                  }}
                />
              </Col>
              <Col offset={1}>{getStatus(CEX_STATUS, record.state)}</Col>
            </Row>
          ) : null}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'allowedActions',
      key: 'allowedActions',
      align: 'left' as 'left', //source of hack https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      render: (allowedActions: any, record: MiniCexSummaryType, index: any) =>
        renderButtons(record, index),
    },
  ];

  return (
    <>
      {!!summaryList && (
        <Table
          pagination={false}
          columns={columns}
          dataSource={summaryList}
          size="small"
          bordered
          tableLayout="auto"
          className="ecfmg-small-table-pathway6"
        ></Table>
      )}
      <p style={{ fontSize: '11px', marginTop: '10px' }}>
        All times are calculated using Coordinated Universal Time (UTC)
      </p>
      <Modal
        title="Review Physician Details"
        visible={isReviewPhysicianDetailsModalVisible}
        onCancel={() => setIsReviewPhysicianDetailsModalVisible(false)}
        width={'80vw'}
        footer={[
          <Button
            key="viewEvaluatorRequests"
            onClick={() => {
              window.open(
                `${window.location.protocol}//${window.location.host}/physician-management/physician-detail/${currentRecord?.physicianEmail}`,
                '_blank'
              );
            }}
          >
            View Evaluator Requests <SelectOutlined />
          </Button>,
          <Button key="close" onClick={() => setIsReviewPhysicianDetailsModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Pathway6ReviewPhysicianDetails
          currentRecord={currentRecord}
          currentPhysicianData={currentPhysicianData}
        />
      </Modal>
      <Modal
        title="Reject Mini-CEX Request?"
        visible={isRejectMiniCEXVisible}
        closable={false}
        width={'450px'}
        footer={[
          <Button key="cancel" onClick={() => setIsRejectMiniCEXVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="rejectMiniCEX"
            onClick={() => onRejectMiniCEX()}
            danger
            type="primary"
            loading={rejectMiniCEXLoading}
          >
            Reject Mini-CEX Request
          </Button>,
        ]}
      >
        <Pathway6RejectMiniCEXRequest currentRecord={currentRecord} />
      </Modal>
      <Modal
        title="Status History"
        visible={isStatusHistoryVisible}
        closable={false}
        width={'450px'}
        footer={[
          <Button key="close" type="primary" onClick={() => setIsStatusHistoryVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Pathway6MiniCEXStatusHistory
          stateModel={currentRecord?.stateModel}
          encounterId={currentRecord?.encounterId}
        />
      </Modal>
      <Modal
        title="Review Completed Mini-CEX"
        visible={isReadOnlyMiniCEXVisible}
        width="fit-content"
        bodyStyle={{ minWidth: '30vw' }}
        onCancel={() => {
          setIsReadOnlyMiniCEXVisible(false);
        }}
        destroyOnClose
        footer={[]}
      >
        <Tabs type="card">
          <TabPane tab="Mini-CEX Summary" key="1">
            {getMiniCEXPDF(readOnlyMiniCEXData, currentPhysicianData)}
          </TabPane>

          <TabPane tab="Mini-CEX Evaluation Attestation" key="2">
            {getMiniCEXAttestationPDF(readOnlyMiniCEXData, currentPhysicianData)}
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        title="Review Rejected Mini-CEX"
        visible={isReviewRejectedMiniCEXVisible}
        width={'60%'}
        onCancel={() => {
          setIsReviewRejectedMiniCEXVisible(false);
        }}
        footer={[
          <Button type="primary" onClick={() => setIsReviewRejectedMiniCEXVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <ReviewRejectedMiniCEXFactory
          miniCEX={readOnlyMiniCEXData}
          physician={currentPhysicianData}
        />
      </Modal>
    </>
  );
};
export default Pathway6MiniCEXActiveTable;
