import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/client/react';
import { CaseListFilterModalContainer } from './case-list-filter-model.container';
import CaseListTable from './case-list-table';
import CaseListFilter from './case-list-filter';
import { Spin, message, Typography } from 'antd';
import _ from 'lodash';
import {
  NUMBER_OF_RECORDS_PER_PAGE,
  DEFAULT_VIEW_ID,
  DASHBOARD_TYPE,
  CASE_LIST_COLUMN_KEYS,
  SORT_FIELD,
  SORT_ORDER,
} from '../../constants';
import { useFeatureFlags } from '../../feature-flag-react-lite';
import {
  CaseMgmtCaseListLayoutCasePortalDocument,
  CaseSummaryType,
  CaseMgmtCaseListLayoutGetDashboardsDocument,
  CaseMgmtCaseListLayoutSaveLastAccessedViewDocument,
  CaseMgmtCaseListAddViewFiltersDocument,
  CaseMgmtCaseListDeleteViewFiltersDocument,
  CaseMgmtCaseListSaveViewFiltersDocument,
  CaseFilterInput,
  CaseListPaginationInput,
} from '../../../generated';

const { Title } = Typography;

interface ViewFilters {
  caseState: string[];
  generalEligibilityStatus?: string[];
  pathwayEligibilityStatus: string[];
  englishEligibilityStatus: string[];
  pathway: string[];
}

interface ViewsInput {
  _id: string;
  viewName?: string;
  viewFilters: ViewFilters;
  displayColumns: string[];
}

//Object for holding the boolean values needed to load various case list columns
interface GetColumnData {
  getUsmleIDColumnData: boolean;
  getDaysSinceLastUpdateColumnData: boolean;
  getSubmissionDateColumnData: boolean;
  getPathwayColumnData: boolean;
  getCaseStatusColumnData: boolean;
  getGeneralEligibilityColumnData: boolean;
  getOetCaseStatusColumnData: boolean;
  getPathwayCaseStatusColumnData: boolean;
  getQaReworkColumnData: boolean;
  getAssignedToColumnData: boolean;
  getLastDocUploadTimestampColumnData: boolean;
}

const CaseList: FC<any> = () => {
  const navigate = useNavigate();
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [viewList, setViewList] = useState<any>([]);
  const [caseFilterInput, setCaseFilterInput] = useState<any>(undefined);
  const [currentViewId, setCurrentViewId] = useState<string | undefined>(DEFAULT_VIEW_ID);
  const [defaultGlobalViewId, setDefaultGlobalViewId] = useState<string | undefined>(undefined);
  const [assignedToMeChecked, setAssignedToMeChecked] = useState<boolean>(false);
  const [numberOfRecordsPerPage, setNumberOfRecordsPerPage] = useState(
    NUMBER_OF_RECORDS_PER_PAGE[0].value
  );
  const [currentCaseListPage, setCurrentCaseListPage] = useState<number>(1);
  const [currentSortField, setCurrentSortField] = useState<string>(SORT_FIELD.SUBMISSION_DATE);
  const [currentSortOrder, setCurrentSortOrder] = useState<string>();
  const [triggerTableReload, setTriggerTableReload] = useState<boolean>(false);
  const [caseListColumnTitleUIState, setCaseListColumnTitleUIState] = useState<{
    [key: string]: any;
  }>({
    [SORT_FIELD.ASSIGNED_TO]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.CASE_STATUS]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.DOCUMENT_LAST_UPDATE_DATE]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.GENERAL_ELIGIBILITY]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.LAST_UPDATE_DATE]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.OET_CASE_STATUS]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.PATHWAY]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.PATHWAYS_CASE_STATUS]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
    [SORT_FIELD.SUBMISSION_DATE]: {
      [SORT_ORDER.ASC]: 0,
      [SORT_ORDER.DESC]: 0,
      [SORT_ORDER.NOSORT]: 0,
    },
  });
  const { GetFeatureFlagByName } = useFeatureFlags();
  const [saveLastAccessedViewMutation] = useMutation(
    CaseMgmtCaseListLayoutSaveLastAccessedViewDocument
  );
  const UseStickyView: boolean = GetFeatureFlagByName('LastAccessedView') === 'true';

  let CaseFilterInput: CaseFilterInput;
  let CaseListPaginationInput: CaseListPaginationInput;
  let GetColumnData: GetColumnData;

  const saveLastAccessedView = async (
    newCurrentViewId: string,
    newNumberOfRecordsPerPage: number,
    newAssignedToMeChecked: boolean
  ) => {
    if (!UseStickyView) return;
    const newValue = {
      input: {
        viewId: newCurrentViewId === DEFAULT_VIEW_ID ? null : newCurrentViewId,
        recordsPerPage: newNumberOfRecordsPerPage,
        assignedToMe: newAssignedToMeChecked,
      },
    };
    console.log('Saving view preference:', newValue);
    await saveLastAccessedViewMutation({
      variables: newValue,
    });
  };

  const {
    loading: viewsLoading,
    error: viewsError,
    data: viewsData,
  } = useQuery(CaseMgmtCaseListLayoutGetDashboardsDocument, { fetchPolicy: 'network-only' });

  const [deleteViewFilters] = useMutation(CaseMgmtCaseListDeleteViewFiltersDocument, {
    refetchQueries: [{ query: CaseMgmtCaseListLayoutGetDashboardsDocument }],
    awaitRefetchQueries: true,
  });

  const [addViewFilters] = useMutation(CaseMgmtCaseListAddViewFiltersDocument, {
    refetchQueries: [{ query: CaseMgmtCaseListLayoutGetDashboardsDocument }],
    awaitRefetchQueries: true,
  });

  const [updateViewFilters] = useMutation(CaseMgmtCaseListSaveViewFiltersDocument, {
    refetchQueries: [{ query: CaseMgmtCaseListLayoutGetDashboardsDocument }],
    awaitRefetchQueries: true,
  });

  //load view for View dropdown
  useEffect(() => {
    if (viewsData?.getDashboards && viewsData?.getDashboards.length > 0) {
      var globalViews = _.find(viewsData.getDashboards, {
        dashboardType: 'global',
      })?.views;
      globalViews = globalViews ? JSON.parse(JSON.stringify(globalViews)) : [];
      //add a tag to the end of view names to incidate global views
      globalViews?.forEach((view: any) => {
        view.viewType = DASHBOARD_TYPE.Global;
        view.viewName += ' (Global)';
      });
      var userSettings = _.find(viewsData.getDashboards, { dashboardType: 'user' });
      var userViews = userSettings?.views ?? [];

      var userViewDefaults = userSettings?.lastAccessedCaseView
        ? {
            assignedToMe: userSettings?.lastAccessedCaseView.assignedToMe,
            recordsPerPage: userSettings?.lastAccessedCaseView.recordsPerPage,
            viewId: userSettings?.lastAccessedCaseView.viewId,
          }
        : {
            assignedToMe: false,
            recordsPerPage: NUMBER_OF_RECORDS_PER_PAGE[0].value,
            viewId: globalViews && globalViews.length > 0 ? globalViews?.[0]?._id : '',
          };

      userViewDefaults.viewId =
        userViewDefaults.viewId === null ? DEFAULT_VIEW_ID : userViewDefaults.viewId;

      setAssignedToMeChecked(userViewDefaults?.assignedToMe!);
      setNumberOfRecordsPerPage(userViewDefaults?.recordsPerPage!);

      var allViews = globalViews?.concat(userViews);
      //If we have a global view, set that as the default
      if (globalViews && globalViews.length > 0) {
        setDefaultGlobalViewId(globalViews?.[0]?._id);
      }
      setViewList(allViews);

      changeView(
        userViewDefaults.viewId,
        userViewDefaults?.assignedToMe!,
        allViews,
        userViewDefaults?.recordsPerPage!
      );
    }
  }, [viewsData]);

  //Update the view when any of the filters change
  useEffect(() => {
    if (triggerTableReload) {
      changeView(currentViewId ?? DEFAULT_VIEW_ID, assignedToMeChecked);
      setTriggerTableReload(false);
    }
  }, [numberOfRecordsPerPage, currentSortField, currentSortOrder, currentCaseListPage]);

  const upAddView = async (filter: any, input: ViewsInput, updateview: boolean) => {
    if (!updateview) {
      //add new
      var currentViewIds: any = [];
      viewList?.forEach((view: any) => {
        currentViewIds.push(view._id);
      });

      await addViewFilters({
        variables: {
          input: input,
        },
      })
        .then((res: any) => {
          const addedViewId = _.find(res.data.addViewFilters.views, function (view) {
            return !_.includes(currentViewIds, view._id);
          })._id;
          message.success('Save successfully!');
        })
        .catch((ex) => {
          //only grab the first error
          const graphQLErrorReference = ex?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${ex.toString()} - Reference ID : ${graphQLErrorReference}`);
        });
    } else {
      //update
      await updateViewFilters({
        variables: {
          input: input,
        },
        //refetchQueries: [`getviews`],
      })
        .then(() => {
          //setCurrentViewId(updateViewId);
          message.success('Save successfully!');
        })
        .catch((ex) => {
          //only grab the first error
          const graphQLErrorReference = ex?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${ex.toString()} - Reference ID : ${graphQLErrorReference}`);
        });
    }
  };

  const toggleShowFilerModal = async () => {
    setShowFilterModal(!showFilterModal);
  };

  const changeView = (
    currentViewIdParam: string,
    assignedToMeCheckedParam: boolean,
    viewListParam: any = viewList,
    numberOfRecordsPerPageParam: number = numberOfRecordsPerPage,
    caseListPage: number = currentCaseListPage
  ) => {
    //Update page number if parameter is not default
    if (caseListPage !== currentCaseListPage) {
      setCurrentCaseListPage(caseListPage);
    }

    //Create object type for below
    CaseFilterInput = {
      assignedToUser: assignedToMeCheckedParam,
      caseState: [] as any,
      currentPathway: [] as any,
      englishEligibilityStatus: [] as any,
      generalEligibilityStatus: [] as any,
      pathwayEligibilityStatus: [] as any,
      usmleId: '',
      school: [] as any,
      regulatoryAuthority: [] as any
    };

    var CaseListPaginationInput: CaseListPaginationInput;

    //The default sort is on Submission Date Descending
    if (currentSortOrder === SORT_ORDER.NOSORT) {
      CaseListPaginationInput = {
        pageNumber: caseListPage,
        pageSize: numberOfRecordsPerPageParam
          ? numberOfRecordsPerPageParam
          : NUMBER_OF_RECORDS_PER_PAGE[0].value,
        sortField: SORT_FIELD.SUBMISSION_DATE,
        sortOrder: SORT_ORDER.DESC,
      } as CaseListPaginationInput;
    } else {
      CaseListPaginationInput = {
        pageNumber: caseListPage,
        pageSize: numberOfRecordsPerPageParam
          ? numberOfRecordsPerPageParam
          : NUMBER_OF_RECORDS_PER_PAGE[0].value,
        sortField: currentSortField,
        sortOrder: currentSortOrder ? currentSortOrder : SORT_ORDER.DESC,
      } as CaseListPaginationInput;
    }

    GetColumnData = {
      getUsmleIDColumnData: true,
      getDaysSinceLastUpdateColumnData: true,
      getSubmissionDateColumnData: true,
      getPathwayColumnData: true,
      getCaseStatusColumnData: true,
      getGeneralEligibilityColumnData: true,
      getOetCaseStatusColumnData: true,
      getPathwayCaseStatusColumnData: true,
      getQaReworkColumnData: true,
      getAssignedToColumnData: true,
      getLastDocUploadTimestampColumnData: true,
    } as GetColumnData;

    if (currentViewIdParam !== DEFAULT_VIEW_ID) {
      const chosenView = viewListParam?.find((view: any) => {
        return view._id === currentViewIdParam;
      });

      CaseFilterInput.caseState = chosenView?.viewFilters.caseState;
      CaseFilterInput.currentPathway = chosenView?.viewFilters.pathway;
      CaseFilterInput.pathwayEligibilityStatus = chosenView?.viewFilters.pathwayEligibilityStatus;
      CaseFilterInput.englishEligibilityStatus = chosenView?.viewFilters.englishEligibilityStatus;
      CaseFilterInput.generalEligibilityStatus = chosenView?.viewFilters.generalEligibilityStatus;
      CaseFilterInput.school = chosenView?.viewFilters.school;
      CaseFilterInput.regulatoryAuthority = chosenView?.viewFilters.regulatoryAuthority;

      //if chosenView?.displayColumns.length is zero, we show all columns
      if (chosenView?.displayColumns.length > 0) {
        setAllColumnDataParamsToFalse(GetColumnData); //For non-default view start with all columns as false
        setColumnDataParams(chosenView?.displayColumns, GetColumnData);
      }
    }
    setCurrentViewId(currentViewIdParam);
    setCaseFilterInput(CaseFilterInput);

    getCases({
      variables: {
        filter: CaseFilterInput,
        pagination: CaseListPaginationInput,
        usmleIDColumn: GetColumnData.getUsmleIDColumnData,
        daysSinceLastUpdateColumn: GetColumnData.getDaysSinceLastUpdateColumnData,
        submissionDateColumn: GetColumnData.getSubmissionDateColumnData,
        pathwayColumn: GetColumnData.getPathwayColumnData,
        caseStatusColumn: GetColumnData.getCaseStatusColumnData,
        generalEligibilityColumn: GetColumnData.getGeneralEligibilityColumnData,
        oetCaseStatusColumn: GetColumnData.getOetCaseStatusColumnData,
        pathwayCaseStatusColumn: GetColumnData.getPathwayCaseStatusColumnData,
        qaReworkColumn: GetColumnData.getQaReworkColumnData,
        assignedToColumn: GetColumnData.getAssignedToColumnData,
        lastDocUploadColumn: GetColumnData.getLastDocUploadTimestampColumnData,
      },
    });
  };

  const onDeleteView = async (_id: string) => {
    await deleteViewFilters({
      variables: {
        dashboardId: _id,
        dashboardType: 'Dashboard', //there are 2 dashboard types: Dashboard and OET (From Mahesh)
      },
    })
      .then(() => {
        if (currentViewId === _id) {
          if (defaultGlobalViewId) {
            setCurrentViewId(defaultGlobalViewId);
          } else {
            setCurrentViewId(DEFAULT_VIEW_ID);
          }
        }
        message.success('Delete successfully!');
      })
      .catch((ex) => {
        //only grab the first error
        const graphQLErrorReference = ex?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${ex.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const setAllColumnDataParamsToFalse = (getColumnDataObject: GetColumnData) => {
    getColumnDataObject.getUsmleIDColumnData = false;
    getColumnDataObject.getDaysSinceLastUpdateColumnData = false;
    getColumnDataObject.getSubmissionDateColumnData = false;
    getColumnDataObject.getPathwayColumnData = false;
    getColumnDataObject.getCaseStatusColumnData = false;
    getColumnDataObject.getGeneralEligibilityColumnData = false;
    getColumnDataObject.getOetCaseStatusColumnData = false;
    getColumnDataObject.getPathwayCaseStatusColumnData = false;
    getColumnDataObject.getQaReworkColumnData = false;
    getColumnDataObject.getAssignedToColumnData = false;
  };

  const setColumnDataParams = (currentDisplayColumns: any, getColumnDataObject: GetColumnData) => {
    currentDisplayColumns.forEach((column: any) => {
      switch (column) {
        case CASE_LIST_COLUMN_KEYS.column_usmle_id:
          getColumnDataObject.getUsmleIDColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_days_since_last_update:
          getColumnDataObject.getDaysSinceLastUpdateColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.submittedAt:
          getColumnDataObject.getSubmissionDateColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_pathway:
          getColumnDataObject.getPathwayColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_case_status:
          getColumnDataObject.getCaseStatusColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_general_eligibility:
          getColumnDataObject.getGeneralEligibilityColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_oet_case_status:
          getColumnDataObject.getOetCaseStatusColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_pathway_case_status:
          getColumnDataObject.getPathwayCaseStatusColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_qa_rework:
          getColumnDataObject.getQaReworkColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_assigned_to:
          getColumnDataObject.getAssignedToColumnData = true;
          break;

        case CASE_LIST_COLUMN_KEYS.column_last_doc_upload_timestamp:
          getColumnDataObject.getAssignedToColumnData = true;
          break;
      }
    });
  };

  const [getCases, { loading, error, data }] = useLazyQuery(
    CaseMgmtCaseListLayoutCasePortalDocument
  );

  if (viewsLoading === true) {
    return (
      <div style={{ textAlign: 'center', margin: '80px 40px' }}>
        <Spin size="large" />
        <Title level={2}>Loading Views</Title>
      </div>
    );
  }

  const onSortFieldChange = (sortField: string) => {
    resetSortArrows();

    var newCaseListTitleUIState = caseListColumnTitleUIState;

    //If the sort field hasn't changed, toggle the sort direction
    if (sortField === currentSortField) {
      setTriggerTableReload(true);

      //Check to see if the first sort is on the Submission Date column
      if (sortField === SORT_FIELD.SUBMISSION_DATE && currentSortOrder === undefined) {
        newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 1;
        setCurrentSortOrder(SORT_ORDER.ASC);
      }
      //Last_update_date column needs to be sorted in oposite direction of other columns
      else if (sortField === SORT_FIELD.LAST_UPDATE_DATE) {
        if (currentSortOrder === SORT_ORDER.ASC) {
          newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.DESC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.NOSORT] = 1;
          setCurrentSortOrder(SORT_ORDER.NOSORT);
        } else if (currentSortOrder === SORT_ORDER.DESC) {
          newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.DESC] = 1;
          newCaseListTitleUIState[sortField][SORT_ORDER.NOSORT] = 0;
          setCurrentSortOrder(SORT_ORDER.ASC);
        } else if (currentSortOrder === SORT_ORDER.NOSORT) {
          newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 1;
          newCaseListTitleUIState[sortField][SORT_ORDER.DESC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.NOSORT] = 0;
          setCurrentSortOrder(SORT_ORDER.DESC);
        }
      } else {
        if (currentSortOrder === SORT_ORDER.ASC) {
          newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.DESC] = 1;
          newCaseListTitleUIState[sortField][SORT_ORDER.NOSORT] = 0;
          setCurrentSortOrder(SORT_ORDER.DESC);
        } else if (currentSortOrder === SORT_ORDER.DESC) {
          newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.DESC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.NOSORT] = 1;
          setCurrentSortOrder(SORT_ORDER.NOSORT);
        } else if (currentSortOrder === SORT_ORDER.NOSORT) {
          newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 1;
          newCaseListTitleUIState[sortField][SORT_ORDER.DESC] = 0;
          newCaseListTitleUIState[sortField][SORT_ORDER.NOSORT] = 0;
          setCurrentSortOrder(SORT_ORDER.ASC);
        }
      }
    } else {
      newCaseListTitleUIState[sortField][SORT_ORDER.ASC] = 1;
      //Last update date column needs to be sorted in oposite of other columns
      if (sortField === SORT_FIELD.LAST_UPDATE_DATE) {
        setCurrentSortOrder(SORT_ORDER.DESC);
      } else {
        setCurrentSortOrder(SORT_ORDER.ASC);
      }
      setTriggerTableReload(true);
      setCurrentSortField(sortField);
    }
    setCaseListColumnTitleUIState(newCaseListTitleUIState);
  };

  const onPageChange = (page: number) => {
    setTriggerTableReload(true);
    setCurrentCaseListPage(page);
  };

  //Set all sort carets on case list table back to grey
  const resetSortArrows = () => {
    var newCaseListTitleUIState = caseListColumnTitleUIState;
    for (let i = 0; i < Object.keys(SORT_FIELD).length; i++) {
      if (Object.keys(SORT_FIELD).map((key) => SORT_FIELD[key as keyof typeof SORT_FIELD])[i]) {
        newCaseListTitleUIState[
          Object.keys(SORT_FIELD).map((key) => SORT_FIELD[key as keyof typeof SORT_FIELD])[i]
        ][SORT_ORDER.ASC] = 0;
        newCaseListTitleUIState[
          Object.keys(SORT_FIELD).map((key) => SORT_FIELD[key as keyof typeof SORT_FIELD])[i]
        ][SORT_ORDER.DESC] = 0;
      }
    }
    setCaseListColumnTitleUIState(newCaseListTitleUIState);
  };

  var CaseListPlaceholder = () => {
    if (loading === true) {
      return (
        <div style={{ textAlign: 'center', margin: '80px 40px' }}>
          <Spin size="large" />
          <Title level={2}>Loading Cases</Title>
        </div>
      );
    } else if (error) {
      console.log('ERROR CASE LIST', error);
      return <p>{'Error2 :( --> (CaseList)'}</p>;
    } else {
      return !!data?.casePortal?.caseList?.cases ? (
        <CaseListTable.Component
          initialTableData={data.casePortal.caseList.cases as CaseSummaryType}
          initialViewList={viewList}
          viewId={currentViewId}
          numberOfRecordsPerPage={numberOfRecordsPerPage}
          totalRecords={data.casePortal.caseList.pagination.totalRecords}
          pageNumber={data.casePortal.caseList.pagination.pageNumber}
          onSortFieldChange={onSortFieldChange}
          onPageChange={onPageChange}
          caseListColumnTitleUIState={caseListColumnTitleUIState}
        />
      ) : null;
    }
  };

  return (
    <>
      <CaseListFilter.Component
        showModal={() => toggleShowFilerModal()}
        numberOfRecordsPerPage={numberOfRecordsPerPage}
        onNumberofRecordsPerPageChangeCallback={(newNumberOfRecordsPerPage: number) => {
          console.log(
            'Setting value from user interaction setting NumberOfRecordsPerPage: ',
            newNumberOfRecordsPerPage
          );
          setCurrentCaseListPage(1);
          setTriggerTableReload(true);
          setNumberOfRecordsPerPage(newNumberOfRecordsPerPage);
          saveLastAccessedView(currentViewId ?? '', newNumberOfRecordsPerPage, assignedToMeChecked);
        }}
        initialViewList={viewList}
        defaultSelectValue={currentViewId}
        changeView={(newViewId: string) => {
          changeView(newViewId, assignedToMeChecked, viewList, numberOfRecordsPerPage, 1);
          saveLastAccessedView(newViewId, numberOfRecordsPerPage, assignedToMeChecked);
        }}
        assignedToMeBoolean={assignedToMeChecked}
        updateAssignedToMeBoolean={(newIsAssignedToMeValue: boolean) => {
          console.log(newIsAssignedToMeValue);
          console.log(
            'Setting value from user interaction setting setAssignedToMeChecked:',
            newIsAssignedToMeValue
          );
          setAssignedToMeChecked(newIsAssignedToMeValue);
          changeView(
            currentViewId ?? '',
            newIsAssignedToMeValue,
            viewList,
            numberOfRecordsPerPage,
            1
          );
          saveLastAccessedView(currentViewId ?? '', numberOfRecordsPerPage, newIsAssignedToMeValue);
        }}
      />
      <CaseListPlaceholder />
      <br />
      <CaseListFilterModalContainer
        initialValues={{ showFilterModal: showFilterModal }}
        caseFilterInput={caseFilterInput}
        initialViewList={viewList}
        chooseViewCallback={(viewId: any) => {
          changeView(viewId ?? DEFAULT_VIEW_ID, assignedToMeChecked);
          setShowFilterModal(false);
        }}
        onCancel={() => {
          changeView(currentViewId ?? DEFAULT_VIEW_ID, assignedToMeChecked);
          setShowFilterModal(false);
        }}
        onSave={(filter: any, dashboardInput: any, updateview: any) =>
          upAddView(filter, dashboardInput, updateview)
        }
        onDelete={(_id: any) => onDeleteView(_id)}
      />
    </>
  );
};

export default CaseList;
