import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Spin } from 'antd';
import { Link, matchPath, useMatch, useResolvedPath } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client/react';
import LeftNavStructure, { NavProps } from './case-left-nav-structure';
import { GetUserGroup, GetUserName } from '../../utils/helperFunctions';
import { case_ } from '../schema/casePortal-case';
import { CaseMgmtCaseLeftNavCasePortalDocument } from '../../generated';
import { ContainerOutlined, DollarOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';

const { Sider } = Layout;

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const LeftNav: FC<any> = (props) => {
  const [caseDetails, setCaseDetails] = useState<any>(undefined);
  const location = useLocation();

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);

  //let caseDetailMatch = matchPath(location.pathname, `/case-management/case-detail/:caseId`);
  let caseDetailMatch = useMatch({ path: `case-management/case-detail/:caseId/*`, end: false });
  let caseIdParam = (
    typeof caseDetailMatch?.params !== undefined
      ? (caseDetailMatch?.params as any)?.caseId ?? ''
      : ''
  ) as string;
  let selectedKeys = caseDetailMatch ? '/case-management/case-detail' : location.pathname;

  const [
    getMenuDetails,
    { loading: caseDetailsLoading, error: caseDetailsError, data: caseDetailsData },
  ] = useLazyQuery(CaseMgmtCaseLeftNavCasePortalDocument);

  const userGroup = GetUserGroup();
  let caseIdParamLoaded = React.useRef(caseIdParam);
  let isRendered = React.useRef(false);
  useEffect(() => {
    (async () => {
      //only run this when we have a usmleID
      if (caseIdParam !== '') {
        // IIFE to make async code work in a non-async Functional Component
        if (caseIdParamLoaded.current !== caseIdParam) {
          console.log(`MENU: IDs Differ ${caseIdParamLoaded.current} - ${caseIdParam}`);
          caseIdParamLoaded.current = caseIdParam;
          getMenuDetails({ variables: { usmleId: caseIdParam } });
        } else if (!isRendered.current) {
          isRendered.current = true;
          console.log('SHOULD LOAD!!!!');

          getMenuDetails({ variables: { usmleId: caseIdParam } });
        }
      }
    })();
  }, [caseIdParam, getMenuDetails, isRendered]);

  console.log('caseIdParam:', caseIdParam);

  useEffect(() => {
    if (caseDetailsLoading) {
      console.info(`HOWDY I'm loading!`);
    }
    if (caseDetailsError) {
      console.info(`HOWDY I'm error!`, caseDetailsError);
    }
    if (caseDetailsData) {
      console.info(`HOWDY! Got some data`, caseDetailsData);
      setCaseDetails(caseDetailsData);
    }
  }, [caseDetailsLoading, caseDetailsError, caseDetailsData, caseDetails, caseIdParam]);

  useEffect(() => {
    if (!isExpanded) {
      const icons = document.querySelectorAll('.ant-menu-item.sider-icon');
      icons.forEach((icon) => icon.setAttribute('style', 'white-space: normal'));
    }
    // const caseListMenuItem = document.querySelector('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:first-child')
    // console.log("CASE LIST ", caseListMenuItem)
    // const siderMenuItem = document.querySelectorAll('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item.sider-icon')
    // console.log("SIDER MENU ", siderMenuItem)

    // let itemAlreadySelected = false
    // for (let i = 1; i < siderMenuItem.length; i++) {
    //   if (siderMenuItem[i].classList.contains('ant-menu-item-selected')) {
    //     itemAlreadySelected = true
    //   }
    // }

    // if (!itemAlreadySelected) {
    //   caseListMenuItem?.classList.add('ant-menu-item-selected')
    // }
  });

  const getLeftNavMenu = (userGroupName: string) => {
    //userGroupName = "Finance"
    console.log('userGroup', userGroup);
    if (userGroup?.includes(userGroupName)) {
      return (
        <Menu.Item
          key="finance-settings"
          icon={<DollarOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
        >
          <Link to={'/case-management/finance-settings'}>Finance Settings</Link>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item
          key="physician-management"
          icon={<MedicineBoxOutlined />}
          className="sider-icon"
          style={{ paddingLeft: '15px' }}
        >
          <Link to={`/physician-management`}>Physician List</Link>
        </Menu.Item>
      );
    }
  };

  const handleToggler = () => {
    const icons = document.querySelectorAll('.ant-menu-item.sider-icon');
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem('sidebar-collapsed', 'true');
      icons.forEach((icon) => icon.setAttribute('style', 'white-space: normal'));
      return;
    }
    setIsExpanded(true);
    localStorage.removeItem('sidebar-collapsed');
    icons.forEach((icon) => icon.setAttribute('style', 'white-space: nowrap'));
  };

  const giveCaseListAnID = () => {
    const caseListMenuItem = document.querySelector(
      '.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:first-child'
    );
    if (caseListMenuItem) {
      caseListMenuItem.setAttribute('id', 'case-list');
    }
  };

  console.log('LOCAL ', localStorage);
  return (
    <>
      {caseDetailsData ? (
        !!caseDetailsData ? (
          <>
            <LeftNavStructure CaseDetailData={caseDetailsData as NavProps['CaseDetailData']} />
          </>
        ) : null
      ) : (
        <Sider
          theme="light"
          className="site-layout-background"
          collapsible
          collapsed={!isExpanded}
          onCollapse={handleToggler}
          style={{
            overflow: 'auto',
            height: 'calc(100vh - 64px)',
            position: 'relative',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Menu
            mode="inline"
            forceSubMenuRender={true}
            defaultSelectedKeys={['case-management']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item
              key="case-management"
              icon={<ContainerOutlined />}
              className="sider-icon"
              style={{ paddingLeft: '15px' }}
              id="case-list"
            >
              <Link to={`/case-management`}>Pathway Case List</Link>
            </Menu.Item>
            {getLeftNavMenu('Finance')}
            {/* <Menu.Item key="/oet-submissions">
              <Link to={`/oet-submissions`}>OET Submissions</Link>
            </Menu.Item>*/}

            <Menu.Divider />
            <Menu.Item
              key="free text"
              disabled={true}
              icon={<ContainerOutlined style={{ visibility: 'hidden' }} />}
              className="sider-icon"
              style={{ cursor: 'default', paddingLeft: '15px' }}
            >
              <div style={{ color: '#3f4373' }}>Logged in as </div>
            </Menu.Item>
            <Menu.Item
              key="userName"
              disabled={true}
              icon={<ContainerOutlined style={{ visibility: 'hidden' }} />}
              className="sider-icon"
              style={{ cursor: 'default', paddingLeft: '15px' }}
            >
              <div style={{ color: '#3f4373' }}>{GetUserName()}</div>
            </Menu.Item>
          </Menu>
        </Sider>
      )}
    </>
  );
};

export default LeftNav;
