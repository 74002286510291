import React, { FC, useState } from 'react';
import { InferProps } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Descriptions, Button, message, Modal, Input, Form } from 'antd';
import { useMutation } from '@apollo/client/react';

import {
  CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveDocument,
  CaseSummaryType,
} from '../../../generated';

const ComponentPropTypes = {};

export interface IProps {
  caseSummary: CaseSummaryType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const CaseOverviewActionResetApplication: FC<ComponentProps> = ({
  caseSummary,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showResetApplicationModal, setShowResetApplicationModal] = useState<boolean>(false);
  const [confirmationValue, setConfirmationValue] = useState<string>('');
  const [archiveApplication, { loading: archiveApplicationLoading }] = useMutation(
    CaseMgmtCaseOverviewActionResetApplicationCasePortalCaseArchiveDocument,
    {
      awaitRefetchQueries: true,
    }
  );

  const resetApplication = async () => {
    if (caseSummary?.dataVersion == undefined) {
      return;
    }

    await archiveApplication({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: caseSummary?.dataVersion,
      },
    })
      .then((data: any) => {
        console.log('Application Archived');
        message.success(`Application for USMLE ID: ${caseSummary?.usmleId} reset successfully!`);
        navigate('/case-management');
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onModalCancel = () => {
    setConfirmationValue('');
    setShowResetApplicationModal(false);
    form.resetFields();
  };

  const onModalOk = () => {
    if (confirmationValue !== caseSummary?.usmleId) {
      message.error('USMLE ID does not match');
      return;
    }

    resetApplication();
  };

  return (
    <>
      <div
        style={{
          minWidth: '100%',
          padding: '10px',
          margin: '5px',
          backgroundColor: '#fafafa',
        }}
      >
        <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
          <Descriptions.Item label="Reset Application">
            <Button onClick={() => setShowResetApplicationModal(true)}>Reset Application</Button>
          </Descriptions.Item>
        </Descriptions>
      </div>

      <Modal
        title="Reset Application"
        visible={showResetApplicationModal}
        closable={false}
        footer={[
          <Button key="cancel" onClick={() => onModalCancel()}>
            Cancel
          </Button>,

          <Button form="submitForm" key="submit" htmlType="submit" type="primary">
            Confirm
          </Button>,
        ]}
      >
        <Form
          id="submitForm"
          form={form}
          layout={'horizontal'}
          size="small"
          onFinish={() => onModalOk()}
          //style={{ border: "solid 1px ", padding: "5px", height: "480px" }}
        >
          <p>
            If this applicant requires a refund, the refund must be issued before resetting the
            application. Please enter USMLE ID below if you still wish to proceed with resetting the
            application
          </p>

          <Form.Item
            initialValue={confirmationValue}
            label="Please enter USMLE ID"
            name="USMLE ID"
            rules={[{ required: true, message: 'Please enter USMLE ID' }]}
          >
            <Input
              value={confirmationValue}
              onChange={(e) => setConfirmationValue(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default {
  Component: CaseOverviewActionResetApplication,
};
