import { CaretRightOutlined } from '@ant-design/icons';
import { View, StyleSheet, Page, Document, Text, Link } from '@react-pdf/renderer';
import moment from 'moment';
import React, { FC } from 'react';
import {
  CaseSummaryType,
  C_ApplicantType,
  EducationAttestation,
  Pathway345,
} from '../../../../../../generated';
import { DATE_FORMAT } from '../../../../../constants';

const styles = StyleSheet.create({
  body: {
    padding: 15,
    margin: 2,
  },
  title: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
  },

  certifyHeader: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,
    paddingBottom: 10,
  },

  certificationTable: {
    display: 'flex',
    width: '100%',
    marginBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },

  certificationTableRow: {
    margin: 'auto',
    flexDirection: 'row',
    marginBottom: 10,
  },

  certificationTableCell: {
    fontSize: 11,
  },

  horizontalLine: {
    borderTop: 1,
    borderColor: 'black',
    height: 1,
    marginBottom: 5,
    marginTop: 2,
  },

  applicantInfoTable: {
    display: 'flex',
    width: '100%',
    marginBottom: 5,
  },

  applicantInfoTableRow: {
    margin: 'auto',
    flexDirection: 'row',
    marginBottom: 10,
  },

  applicantInfoTableCellLabel: {
    fontSize: 10,
  },

  applicantInfoTableCellContent: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,
  },

  certified: { backgroundColor: '#c9f5c6', fontSize: 11, padding: 5, marginBottom: 3 },
  rejected: { backgroundColor: '#f09999', fontSize: 11, padding: 5, marginBottom: 3 },
});

interface InfoGroupProps {
  label: string;
  content: string;
}

const InfoGroup: FC<InfoGroupProps> = ({ label, content }) => (
  <View style={styles.applicantInfoTableRow}>
    <View style={{ width: '40%' }}>
      <View style={styles.applicantInfoTableCellLabel}>
        <Text>{label}</Text>
      </View>
    </View>
    <View style={{ width: '60%' }}>
      <View style={styles.applicantInfoTableCellContent}>
        <Text>{content}</Text>
      </View>
    </View>
  </View>
);

interface CertificationProps {
  text: any;
}
const CertificationLine: FC<any> = ({ text }) => (
  <View style={styles.certificationTableRow}>
    <View style={{ width: '3%' }}>
      <View style={styles.certificationTableCell}>
        <Text>- </Text>
      </View>
    </View>
    <View style={{ width: '97%' }}>
      <View style={styles.certificationTableCell}>
        <Text>{text}</Text>
      </View>
    </View>
  </View>
);

const certifiyText = [
  'The above-named individual is a current student or a graduate of this medical school.',
  'The dean of this medical school or the dean’s designee has received and reviewed three completed Mini-CEX forms (or their equivalents) evaluating clinical patient encounters performed by this applicant.',
  'Each of this applicant’s Mini-CEX forms (or their equivalents) was completed by a different faculty member of the medical school or affiliated clinical rotation site, and these faculty members routinely assess medical student performance.',
  'The dean or dean’s designee has confirmed that the three completed Mini-CEX evaluations (or their equivalents) indicate that the applicant has demonstrated a satisfactory level of clinical skills.',
  'I understand that this ECFMG Clinical Skills Attestation will be used to evaluate this individual’s application to meet the clinical skills requirement for ECFMG Certification for the purpose of entering graduate medical education in the United States.',
  'I am authorized to certify this on behalf of this institution.',
  <>
    I have read the{' '}
    <Link src="https://www.ecfmg.org/programs/irregular-behavior.html">
      ECFMG Policies and Procedures Regarding Irregular Behavior
    </Link>{' '}
    and agree to abide by these policies and procedures. I understand that, as provided in the ECFMG
    Policies and Procedures Regarding Irregular Behavior, ECFMG may find that submission of
    falsified documents to ECFMG constitutes irregular behavior.
  </>,
];

const cannotCertifyText = [
  'I understand that this response will be used to evaluate this individual’s application to meet the clinical skills requirement for ECFMG Certification for the purpose of entering graduate medical education in the United States.',
  'I am authorized to confirm this on behalf of this institution.',
  <>
    I have read the{' '}
    <Link src="https://www.ecfmg.org/programs/irregular-behavior.html">
      ECFMG Policies and Procedures Regarding Irregular Behavior
    </Link>{' '}
    and agree to abide by these policies and procedures. I understand that, as provided in the ECFMG
    Policies and Procedures Regarding Irregular Behavior, ECFMG may find that submission of
    falsified documents to ECFMG constitutes irregular behavior.
  </>,
];

interface AttestationProps {
  attestation: EducationAttestation;
}

interface EMSWPAttestationPDFV1Props {
  caseSummary: CaseSummaryType;
  pathway345: Pathway345;
}

const EMSWPAttestationPDFV1: FC<EMSWPAttestationPDFV1Props> = ({ caseSummary, pathway345 }) => {
  const CertifiedAttestation: FC<AttestationProps> = ({ attestation }) => {
    return (
      <View style={styles.certified}>
        <Text>Status: Certified</Text>
        <Text>Completed By: {attestation?.updatedBy?.name}</Text>
        <Text>Completed On: {moment(attestation?.updatedTimestamp).utc().format(DATE_FORMAT)}</Text>
      </View>
    );
  };
  const RejectedAttestation: FC<AttestationProps> = ({ attestation }) => {
    return (
      <View style={styles.rejected}>
        <Text>Status: Rejected</Text>
        <Text>Rejected By: {attestation?.updatedBy?.name}</Text>
        <Text>Rejected Reason: {attestation?.outputReason}</Text>
        {attestation?.outputReason === 'OTHER' ? (
          <Text>Additional Information: {attestation?.outputReasonText}</Text>
        ) : null}
        <Text>Rejected On: {moment(attestation?.updatedTimestamp).utc().format(DATE_FORMAT)}</Text>
      </View>
    );
  };
  return (
    <>
      <Document title="Clinical Skills Attestation">
        <Page size="A4" style={styles.body} orientation="landscape">
          {/* title */}
          <View>
            <Text>Clinical Skills Attestation</Text>
            <View style={styles.horizontalLine}></View>
          </View>
          {/* applicant's info */}
          <View>
            {/* usmleid */}
            <InfoGroup
              label="Applicant's USMLE/ECFMG Identification Number:"
              content={caseSummary.usmleId ?? ''}
            />
            {/* name */}
            <InfoGroup
              label="Applicant's Name:"
              content={caseSummary.lastName + ', ' + caseSummary.firstName}
            />

            {/* dob */}
            <InfoGroup
              label="Applicant's Date of Birth:"
              content={
                caseSummary.dateOfBirth
                  ? moment(caseSummary.dateOfBirth).format(DATE_FORMAT)
                  : 'N/A'
              }
            />

            {/* school */}
            <InfoGroup
              label="Medical School:"
              content={
                pathway345.applicantProvidedData![0]!.data!.schoolRef!.name +
                ', ' +
                pathway345.applicantProvidedData![0]!.data!.schoolRef!.countryName!
              }
            />
            <View style={styles.horizontalLine}></View>
          </View>

          {/* certification */}
          <View>
            <Text style={styles.certifyHeader}>
              {pathway345.applicantProvidedData![0]?.attestation?.output === 'Rejected'
                ? 'I cannot certify this applicant’s Clinical Skills Attestation.'
                : 'I certify that:'}
            </Text>

            <View style={styles.certificationTable}>
              {pathway345.applicantProvidedData![0]?.attestation?.output === 'Rejected'
                ? cannotCertifyText.map((certification, index) => (
                    <CertificationLine text={certification} />
                  ))
                : certifiyText.map((certification, index) => (
                    <CertificationLine text={certification} />
                  ))}
            </View>

            <View style={styles.horizontalLine}></View>
          </View>
          {/* result */}
          <View>
            {pathway345.applicantProvidedData![0]?.attestation?.output === 'Rejected' ? (
              <RejectedAttestation
                attestation={pathway345.applicantProvidedData![0]!.attestation!}
              />
            ) : (
              <CertifiedAttestation
                attestation={pathway345.applicantProvidedData![0]!.attestation!}
              />
            )}
            <View style={styles.horizontalLine}></View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default EMSWPAttestationPDFV1;
