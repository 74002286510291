import moment from "moment";
import {
  SCREEN_TYPE,
  CASE_LIST_TABLE_COLUMNS,
  OET_DETAIL_TABLE_COLUMNS,
  OET_TABLE_COLUMNS,
} from "../components/constants";
import { Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export function GetUserGroup() {
  const token = sessionStorage.getItem("msal.idtoken");
  const decodedJWT: any = token ? JSON.parse(token) : "";
  return decodedJWT?.roles;
}

export function GetUserName() {
  const token = sessionStorage.getItem("msal.idtoken");
  const decodedJWT: any = token ? JSON.parse(token) : "";
  return decodedJWT?.name;
}

const isTimestampNumber = (timestamp: any): boolean => {
  return [...timestamp].every(c => '0123456789'.includes(c));
}

export const compareDate = (date1: any, date2: any, asc: boolean = false) => {
  //Check timestamp format
  if (isTimestampNumber(date1)) {
    if (asc) return moment(+date1).unix() - moment(+date2).unix();
    return moment(+date2).unix() - moment(+date1).unix();
  }
  else {
    if (asc) return moment(date1).unix() - moment(date2).unix();
    return moment(date2).unix() - moment(date1).unix();
  }
};

export const compareString = (str1: string, str2: string) => {
  if (str1) return str2 ? str1.localeCompare(str2) : -1;
  if (str2) return str1 ? str2.localeCompare(str1) : 1;
  return 0;
};

//used to generate displayed columns used in case list and oet list
export const GetDisplayedColumns = (
  screenType: any,
  url: string,
  displayedColumnKeys: any
) => {
  var allColumns: any;
  var subURL: any;
  var recordId: any;
  if (screenType === SCREEN_TYPE.CASE_LIST) {
    allColumns = CASE_LIST_TABLE_COLUMNS;
    subURL = "case-detail";
  } else if (screenType === SCREEN_TYPE.OET_DETAIL) {
    allColumns = OET_DETAIL_TABLE_COLUMNS;
    subURL = "oet-detail";
  } else {
    allColumns = OET_TABLE_COLUMNS;
    subURL = "oet-detail";
  }

  const actionColumn = {
    title: "Action",
    dataIndex: "action",
    render: (text: any, record: any) => (
      <Space size="middle">
        <Link
          to={`${url}/${subURL}/${
            screenType === SCREEN_TYPE.CASE_LIST
              ? record.usmleId
              : record.candidateId
          }`}
        >
          View
        </Link>
      </Space>
    ),

    align: "center",
  };

  var displayedColumns: any = [];
  if (
    !displayedColumnKeys ||
    displayedColumnKeys?.length === 0 ||
    displayedColumnKeys === allColumns
  ) {
    displayedColumns = [actionColumn, ...allColumns];
  } else {
    allColumns?.forEach((column: any) => {
      if (displayedColumnKeys?.includes(column.key)) {
        displayedColumns.push(column);
      }
    });
    displayedColumns = [actionColumn, ...displayedColumns];
  }

  return displayedColumns;
};
