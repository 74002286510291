import React, { FC, useState, useEffect } from 'react';
import { Form, Row, Col, DatePicker, Button, message } from 'antd';
import moment from 'moment';
import {  useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { Data } from 'react-csv/components/CommonPropTypes';
import { Parser } from 'json2csv';
import { compareDate } from '../../utils/helperFunctions';
import { glDistributionReport } from '../schema/casePortal-glDistributionReport';
import { CaseMgmtTransactionHistoryDetailsCasePortalDocument } from '../../generated';

//headers for csv file
const fields = [
  {
    label: 'USMLE ID',
    value: 'usmleId',
  },
  {
    label: 'TRS Account ID',
    value: 'trsAccountId',
  },
  {
    label: 'Transaction Type',
    value: 'transactionType',
  },
  {
    label: 'Transaction Date',
    value: 'transactionTimestamp',
  },
  {
    label: 'Amount',
    value: 'amount',
  },
  {
    label: 'Payment Network Reference ID',
    value: 'paymentNetworkReferenceId',
  },
  {
    label: 'GL Debit Account ID',
    value: 'glDebitAccountId',
  },
  {
    label: 'GL Credit AccountID',
    value: 'glCreditAccountId',
  },
  {
    label: 'Application Type',
    value: 'applicationType',
  },
];

const dateFormat = 'YYYY-MM-DD';

export interface ComponentProps {
  casePortal: {
    glDistributionReport: glDistributionReport;
  };
}

const TransactionHistoryDetails: FC<any> = (props) => {
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [dateHasChanged, setDateHasChanged] = useState(false);
  const [csvData, setCsvData] = useState<string | Data>('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [form] = Form.useForm();

  const [
    getJSONDataForCSV,
    { loading: getDataForCSVLoading, error: getDataForCSVError, data: dataForCSV },
  ] = useLazyQuery(CaseMgmtTransactionHistoryDetailsCasePortalDocument, { fetchPolicy: 'cache-and-network' });

  //const getDataForCSV = useImperativeQuery(GET_CSV_DATA);

  const checkEndDate = (rule: any, value: any) => {
    if (value && moment(value).isBefore(startDate)) {
      return Promise.reject('End date must be greater than Start date');
    }
    return Promise.resolve();
  };

  const downloadCSVTextToFile = (csvText: any) => {
    const element = document.createElement('a');
    const file = new Blob([csvText], {
      type: 'text/csv',
    });
    element.href = URL.createObjectURL(file);
    element.download = `Report from ${moment(startDate).format(dateFormat)} to ${moment(
      endDate
    ).format(dateFormat)}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // setStartDate(startDate);
    // setEndDate(endDate);
    setIsDownloading(false);
    setDateHasChanged(false);
    message.success('Download report successfully!');
  };

  useEffect(() => {
    if (getDataForCSVLoading) {
      setIsDownloading(true);
      return;
    }
    if (getDataForCSVError) {
      message.error('There are errors while downloading. Please try again!');
      setIsDownloading(false);
      return;
    }
    if (dataForCSV) {
      var jsonDataForCSV = JSON.parse(JSON.stringify(dataForCSV?.casePortal?.glDistributionReport));

      _.forEach(jsonDataForCSV, (element) => {
        element.transactionTimestamp = moment(element.transactionTimestamp).format(
          'MM/DD/YYYY HH:mm:ss'
        );
        delete element.__typename;
      });

      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(
        jsonDataForCSV.sort((e1: any, e2: any) =>
          compareDate(e2.transactionTimestamp, e1.transactionTimestamp)
        )
      );
      console.log(csv);
      downloadCSVTextToFile(csv);
    }
  }, [dataForCSV, getDataForCSVLoading, getDataForCSVError]);

  const downloadCSV = async () => {
    //get dates in correct format
    const tempStartDate = moment(startDate).format(dateFormat);
    const tempEndDate = endDate ? moment(endDate).format(dateFormat) : moment().format(dateFormat);

    //call lazy query to get the data in JSON format
    getJSONDataForCSV({
      variables: {
        transactionDateFrom: tempStartDate,
        transactionDateTo: tempEndDate,
      },
    });
  };

  console.log(csvData);
  return (
    <>
      <Form form={form} size="small" layout="inline" onFinish={downloadCSV}>
        <Row gutter={24}>
          <Col lg={10} md={24}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: 'Please enter Start date',
                },
              ]}
            >
              <DatePicker
                value={startDate ? moment(startDate) : undefined}
                onChange={(value) => {
                  value ? setStartDate(value.toDate()) : setStartDate(undefined);
                  setDateHasChanged(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={10} md={24}>
            <Form.Item
              name="endDate"
              label="End Date (optional)"
              rules={[{ validator: checkEndDate }]}
            >
              <DatePicker
                value={endDate ? moment(endDate) : undefined}
                onChange={(value) => {
                  value ? setEndDate(value.toDate()) : setEndDate(undefined);
                  setDateHasChanged(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={4} md={24}>
            {/* <Tooltip
              title={
                !dateHasChanged
                  ? "Please change dates first to enable download button."
                  : ""
              }
            > */}
            <Button
              htmlType="submit"
              loading={isDownloading}
              // disabled={isDownloading || !dateHasChanged}
            >
              Download CSV
            </Button>
            {/* </Tooltip> */}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TransactionHistoryDetails;
