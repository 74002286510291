import React, { FC, useState, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Descriptions,
  Button,
  Row,
  Col,
  Select,
  Popconfirm,
  message,
  Modal,
  Input,
  Tag,
  Form,
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import FinanceActions from '../finance-actions';
import { useMutation } from '@apollo/client/react';
import PathwayLink from './components/pathway-link';
import LanguageAssessmentLink from './components/language-assessment-link';
import { GetUserGroup } from '../../../utils/helperFunctions';
import { useFeatureFlags } from '../../feature-flag-react-lite';
import {
  CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskDocument,
  CaseMgmtCaseOverviewActionsAssignQaReviewTaskDocument,
  CaseMgmtCaseOverviewActionsConvertToExceptionCaseDocument,
  CaseMgmtCaseOverviewActionsOverridePathwayDocument,
  CaseMgmtCaseOverviewActionsQaAcceptDecisionDocument,
  CaseMgmtCaseOverviewActionsQaApproveDocument,
  CaseMgmtCaseOverviewActionsQaOverrideDecisionDocument,
  CaseMgmtCaseOverviewActionsQaRejectDocument,
  CaseMgmtCaseOverviewActionsQaReworkRequiredDocument,
  CaseMgmtCaseOverviewLayoutCasePortalDocument,
  CaseSummaryType,
} from '../../../generated';
import CaseOverviewActionResetApplication from './case-overview-action-reset-application';

const { Option } = Select;
const { TextArea } = Input;

const ComponentPropTypes = {};

export interface IProps {
  caseSummary: CaseSummaryType;
}

export type ComponentProps = InferProps<typeof ComponentPropTypes> & IProps;

const PATHWAYS = {
  pathway1: 'Pathway 1',
  pathway2: 'Pathway 2',
  pathway3: 'Pathway 3',
  pathway4: 'Pathway 4',
  pathway5: 'Pathway 5',
  pathway6: 'Pathway 6',
  exception: 'Exception',
};

const CaseOverviewActions: FC<ComponentProps> = ({ caseSummary }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { GetFeatureFlagByName } = useFeatureFlags();
  let { usmleId } = useParams<any>();

  const [assignedTo, setAssignedTo] = useState<CaseSummaryType['eligibilityReviewAssigneeName']>();
  const [qaAssigned, setQaAssigned] = useState<CaseSummaryType['qaReviewAssigneeName']>();
  const [eotSubCaseStatus, setEotSubCaseStatus] = useState<string | undefined>();
  const [pathwaySubCaseStatus, setPathwaySubCaseStatus] = useState<string | undefined>();
  const [dataVersion, setDataVersion] = useState<CaseSummaryType['dataVersion']>(undefined);
  const [currentPathway, setCurrentPathway] = useState<CaseSummaryType['currentPathway']>(
    undefined
  );
  const [userSelectedPathway, setUserSelectedPathway] = useState<string | undefined>(undefined);
  const [showOverrideModal, setShowOverrideModal] = useState<boolean>(false);
  const [overrideComments, setOverrideComments] = useState<string>('');
  const [
    featureFlagPreventConvertToExceptionCase,
    setFeatureFlagPreventConvertToExceptionCase,
  ] = useState<string>('');
  const [showConfirmPathwayModal, setShowConfirmPathwayModal] = useState<boolean>(false);
  const [confirmUSMLEIDText, setConfirmUSMLEIDText] = useState<string>('');
  const [pathwaySelect, setPathwaySelect] = useState<string>();

  const [changePathway] = useMutation(CaseMgmtCaseOverviewActionsOverridePathwayDocument);
  const [convertToException] = useMutation(
    CaseMgmtCaseOverviewActionsConvertToExceptionCaseDocument
  );
  const [assignEligibilityReviewTask] = useMutation(
    CaseMgmtCaseOverviewActionsAssignEligibilityReviewTaskDocument, {
      refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
      awaitRefetchQueries: true,
    }
  );

  const [assignQAReviewTask] = useMutation(CaseMgmtCaseOverviewActionsAssignQaReviewTaskDocument, {
    refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
    awaitRefetchQueries: true,
  });

  const [qaReworkRequired, { loading: QAReworkRequiredLoading }] = useMutation(
    CaseMgmtCaseOverviewActionsQaReworkRequiredDocument, {
      refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
      awaitRefetchQueries: true,
    }
  );
  const [qaReject, { loading: QARejectLoading }] = useMutation(
    CaseMgmtCaseOverviewActionsQaRejectDocument, {
      refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
      awaitRefetchQueries: true,
    }
  );
  const [qaApprove, { loading: QAApproveLoading }] = useMutation(
    CaseMgmtCaseOverviewActionsQaApproveDocument, {
      refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
      awaitRefetchQueries: true,
    }
  );
  const [qaOverrideDecision, { loading: QAOverrideDecisionLoading }] = useMutation(
    CaseMgmtCaseOverviewActionsQaOverrideDecisionDocument, {
      refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
      awaitRefetchQueries: true,
    }
  );
  const [qaAcceptDecision, { loading: QAAcceptDecisionLoading }] = useMutation(
    CaseMgmtCaseOverviewActionsQaAcceptDecisionDocument, {
      refetchQueries: [{ query: CaseMgmtCaseOverviewLayoutCasePortalDocument, variables: { usmleId: usmleId } }],
      awaitRefetchQueries: true,
    }
  );

  const userGroup = GetUserGroup();

  useEffect(() => {
    if (caseSummary) {
      if (!currentPathway) {
        setCurrentPathway(
          caseSummary?.isExceptionCase ? PATHWAYS.exception : caseSummary?.currentPathway!
        );
      }
      //Set default state
      if (!userSelectedPathway) {
        setUserSelectedPathway(caseSummary?.currentPathway!);
      }

      setAssignedTo(caseSummary?.eligibilityReviewAssigneeName!);
      setQaAssigned(caseSummary?.qaReviewAssigneeName!);
      setEotSubCaseStatus(caseSummary?.englishEligibilityStatus!);
      setPathwaySubCaseStatus(caseSummary?.pathwayEligibilityStatus!);
    }

    setFeatureFlagPreventConvertToExceptionCase(
      GetFeatureFlagByName('PreventConvertToExceptionCase')
    );
  }, []);

  if (caseSummary) {
    if (caseSummary.dataVersion !== dataVersion) {
      setDataVersion(caseSummary?.dataVersion!);
    }
  }

  const onClickSavePathway = async () => {
    if (pathwaySelect && pathwaySelect !== currentPathway) {
      setShowConfirmPathwayModal(true);
    }
  };

  const overridePathway = async () => {
    if (confirmUSMLEIDText.trim() === caseSummary?.usmleId) {
      await changePathway({
        variables: {
          usmleId: caseSummary?.usmleId,
          dataVersion: dataVersion ? +dataVersion : -1,
          input: {
            currentPathway: pathwaySelect ?? '',
            usmleId: confirmUSMLEIDText,
          },
        },
      })
        .then((data) => {
          const casePortalResponse = data?.data?.CasePortal_overridePathway;

          setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
          setCurrentPathway(casePortalResponse?.caseSummary?.currentPathway);
          //page refresh
          navigate(0);
        })
        .catch((error) => {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        });
    } else {
      if (confirmUSMLEIDText.trim().length > 0) {
        message.error('Applicant USMLE ID does not match');
      } else {
        message.error('Please confirm Applicant USMLE ID');
      }
    }
  };

  const onConvertToExceptionClick = async () => {
    if (dataVersion == undefined) {
      return;
    }

    await convertToException({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        console.log(data);
        message.success('Converted to Exception successfully!');
        const casePortalResponse = data?.data?.CasePortal_convertToExceptionCase;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setCurrentPathway(
          casePortalResponse?.caseSummary?.isExceptionCase
            ? 'Exception'
            : casePortalResponse?.caseSummary?.currentPathway
        );
        navigate(0);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onAssignEligibilityReviewTaskClick = async () => {
    if (dataVersion == undefined) {
      return;
    }
    await assignEligibilityReviewTask({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        console.log(data);
        message.success('Assigned successfully!');
        const casePortalResponse = data?.data?.CasePortal_assignEligibilityReviewTask;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setAssignedTo(casePortalResponse?.caseSummary?.eligibilityReviewAssigneeName);
        //refetch();
        //history.go(0);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onAssignQAReviewTaskClick = async () => {
    if (dataVersion == undefined) {
      return;
    }
    await assignQAReviewTask({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        console.log(data);
        message.success('Assigned successfully!');
        const casePortalResponse = data?.data?.CasePortal_assignQAReviewTask;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setQaAssigned(casePortalResponse?.caseSummary?.qaReviewAssigneeName);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onQaReworkRequiredClick = async () => {
    if (dataVersion == undefined) {
      return;
    }

    await qaReworkRequired({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_qa_reworkRequired;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setCurrentPathway(casePortalResponse?.caseSummary?.currentPathway);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onQaRejectClick = async () => {
    if (dataVersion == undefined) {
      return;
    }

    await qaReject({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_qa_reject;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setCurrentPathway(casePortalResponse?.caseSummary?.currentPathway);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onQaApproveClick = async () => {
    if (dataVersion == undefined) {
      return;
    }
    await qaApprove({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_qa_approve;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setCurrentPathway(casePortalResponse?.caseSummary?.currentPathway);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onQaAcceptDecision = async () => {
    if (dataVersion == undefined) {
      return;
    }

    await qaAcceptDecision({
      variables: {
        usmleId: caseSummary?.usmleId!,
        dataVersion: dataVersion,
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_qa_acceptDecision;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setCurrentPathway(casePortalResponse?.caseSummary?.currentPathway);
      })
      .catch((error) => {
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onQaOverride = async () => {
    if (dataVersion == undefined || !caseSummary?.usmleId) {
      return;
    }

    var overrideTo = caseSummary?.output === 'Rejected' ? 'Approved' : 'Rejected';
    await qaOverrideDecision({
      variables: {
        usmleId: caseSummary?.usmleId,
        dataVersion: dataVersion,
        decision: overrideTo,
        comments: overrideComments,
      },
    })
      .then((data) => {
        const casePortalResponse = data?.data?.CasePortal_qa_overrideDecision;

        setDataVersion(casePortalResponse?.caseSummary?.dataVersion);
        setCurrentPathway(casePortalResponse?.caseSummary?.currentPathway);
        setShowOverrideModal(false);
      })
      .catch((error) => {
        setShowOverrideModal(false);
        //only grab the first error
        const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
        message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
      });
  };

  const onCloseConfirmPathwayModal = () => {
    setConfirmUSMLEIDText('');
  };

  const updateDataVersion = (dataVersion: string) => {
    setDataVersion(parseInt(dataVersion));
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
        <Col md={24} xl={8}>
          <LanguageAssessmentLink.Component
            key={caseSummary?._id!}
            id={caseSummary?._id!}
            usmleId={caseSummary?.usmleId!}
          />

          <Descriptions>
            <Descriptions.Item label="Status">{eotSubCaseStatus}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col md={24} xl={8}>
          <PathwayLink.Component
            key={caseSummary?._id!}
            id={caseSummary?._id!}
            usmleId={caseSummary?.usmleId!}
          />

          <Descriptions>
            <Descriptions.Item label="Status">{pathwaySubCaseStatus}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col md={24} xl={8}>
          {/*  Only show if case has not been submitted yet */}
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={onConvertToExceptionClick}
            disabled={
              caseSummary?.caseState !== 'ApplicationCreation' ||
              caseSummary?.isExceptionCase ||
              featureFlagPreventConvertToExceptionCase === 'true'
            }
          >
            <Button
              style={{ minWidth: '100%' }}
              size="small"
              disabled={
                caseSummary?.caseState !== 'ApplicationCreation' ||
                caseSummary?.isExceptionCase ||
                !(userGroup && userGroup?.includes('CaseManager')) ||
                featureFlagPreventConvertToExceptionCase === 'true'
              }
            >
              Convert to Exception
            </Button>
          </Popconfirm>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
        <Col md={24} xl={8}>
          <div
            style={{
              minWidth: '100%',
              padding: '10px',
              margin: '5px',
              backgroundColor: '#fafafa',
            }}
          >
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="Assigned To">{assignedTo}</Descriptions.Item>
            </Descriptions>
            <div style={{ minWidth: '100%', textAlign: 'right' }}>
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={onAssignEligibilityReviewTaskClick}
                disabled={
                  !(
                    caseSummary?.eligibilityReviewAssigneeId !==
                      sessionStorage.getItem('userIdToken') &&
                    (caseSummary?.caseState?.toUpperCase() ===
                      'ApplicationSubmitted'.toUpperCase() ||
                      caseSummary?.caseState?.toUpperCase() ===
                        'EligibilityReview'.toUpperCase()) &&
                    userGroup &&
                    userGroup?.includes('CaseManager')
                  )
                }
              >
                <Button
                  size="small"
                  disabled={
                    !(
                      caseSummary?.eligibilityReviewAssigneeId !==
                        sessionStorage.getItem('userIdToken') &&
                      (caseSummary?.caseState?.toUpperCase() ===
                        'ApplicationSubmitted'.toUpperCase() ||
                        caseSummary?.caseState?.toUpperCase() ===
                          'EligibilityReview'.toUpperCase()) &&
                      userGroup &&
                      userGroup?.includes('CaseManager')
                    )
                  }
                >
                  Take Case
                </Button>
              </Popconfirm>
            </div>
          </div>
          <div
            style={{
              minWidth: '100%',
              padding: '10px',
              margin: '5px',
              backgroundColor: '#fafafa',
            }}
          >
            <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
              <Descriptions.Item label="QA Assigned">{qaAssigned}</Descriptions.Item>
            </Descriptions>
            <div style={{ minWidth: '100%', textAlign: 'right' }}>
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={onAssignQAReviewTaskClick}
                disabled={
                  !(
                    caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') &&
                    (caseSummary?.caseState?.toUpperCase() === 'QAREADY' ||
                      caseSummary?.caseState?.toUpperCase() === 'QAREVIEW') &&
                    userGroup &&
                    userGroup?.includes('CaseManager')
                  )
                }
              >
                <Button
                  size="small"
                  disabled={
                    !(
                      caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') &&
                      (caseSummary?.caseState?.toUpperCase() === 'QAREADY' ||
                        caseSummary?.caseState?.toUpperCase() === 'QAREVIEW') &&
                      userGroup &&
                      userGroup?.includes('CaseManager')
                    )
                  }
                >
                  Take Case
                </Button>
              </Popconfirm>
            </div>
          </div>
        </Col>
        <Col md={24} xl={8}>
          <div
            style={{
              minWidth: '100%',
              padding: '10px',
              margin: '5px',
              backgroundColor: '#fafafa',
            }}
          >
            {currentPathway === PATHWAYS.exception ? (
              <p>Exception Case</p>
            ) : (
              <>
                <Descriptions className="ecfmg-small-descriptions" size="small" layout="vertical">
                  <Descriptions.Item label="Recommendation">{currentPathway}</Descriptions.Item>
                </Descriptions>
                <Select
                  style={{ width: 100 }}
                  size="small"
                  defaultValue={userSelectedPathway?.toUpperCase()}
                  onChange={(e: any) => {
                    setPathwaySelect(e);
                  }}
                  disabled={
                    caseSummary?.eligibilityReviewAssigneeId !==
                      sessionStorage.getItem('userIdToken') ||
                    caseSummary?.caseState?.toUpperCase() !== 'eligibilityReview'.toUpperCase() ||
                    caseSummary?.isExceptionCase ||
                    caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED'
                  }
                >
                  <Option value="PATHWAY1">Pathway 1</Option>
                  <Option value="PATHWAY2">Pathway 2</Option>
                  <Option value="PATHWAY3">Pathway 3</Option>
                  <Option value="PATHWAY4">Pathway 4</Option>
                  <Option value="PATHWAY5">Pathway 5</Option>
                  <Option value="PATHWAY6">Pathway 6</Option>
                </Select>
                <br />
                <br />
                <div style={{ minWidth: '100%', textAlign: 'right' }}>
                  <Button
                    size="small"
                    onClick={() => onClickSavePathway()}
                    disabled={
                      caseSummary?.eligibilityReviewAssigneeId !==
                        sessionStorage.getItem('userIdToken') ||
                      caseSummary?.caseState?.toUpperCase() !== 'eligibilityReview'.toUpperCase() ||
                      caseSummary?.isExceptionCase ||
                      caseSummary?.pathwayEligibilityStatus?.toUpperCase() === 'COMPLETED'
                    }
                  >
                    Save Change
                  </Button>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col md={24} xl={8}>
          {GetFeatureFlagByName('QAReworkFlag') === 'true' ? (
            <>
              {caseSummary?.caseState === 'QAReview' ? (
                <div
                  style={{
                    minWidth: '100%',
                    padding: '10px',
                    margin: '5px',
                    backgroundColor: '#fafafa',
                  }}
                >
                  QA Actions: <br />
                  <div>
                    <Descriptions
                      className="ecfmg-small-descriptions"
                      size="small"
                      layout="vertical"
                    >
                      <Descriptions.Item label="Recommended Decision">
                        {caseSummary?.output === 'Rejected' ? (
                          <Tag color="#f50">Reject</Tag>
                        ) : (
                          <Tag color="#87d068">Approve</Tag>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div style={{ border: '1px solid #d8d8d8', padding: '10px' }}>
                    <Popconfirm
                      title={`You are about to ${
                        caseSummary?.output === 'Rejected' ? 'Reject' : 'Approve'
                      }, are you sure?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => onQaAcceptDecision()}
                      disabled={
                        caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                        caseSummary?.caseState.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                        !(userGroup && userGroup?.includes('CaseManager'))
                      }
                    >
                      <Button
                        style={{
                          minWidth: '100%',
                          margin: '5px 0',
                        }}
                        size="small"
                        loading={QAAcceptDecisionLoading}
                        disabled={
                          caseSummary?.qaReviewAssigneeId !==
                            sessionStorage.getItem('userIdToken') ||
                          caseSummary?.caseState.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                          !(userGroup && userGroup?.includes('CaseManager'))
                        }
                      >
                        Accept Decision
                      </Button>
                    </Popconfirm>
                    <br></br>
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => onQaReworkRequiredClick()}
                      disabled={
                        caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                        caseSummary?.caseState.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                        !(userGroup && userGroup?.includes('CaseManager'))
                      }
                    >
                      <Button
                        style={{
                          minWidth: '100%',
                          margin: '5px 0',
                        }}
                        size="small"
                        loading={QAReworkRequiredLoading}
                        disabled={
                          caseSummary?.qaReviewAssigneeId !==
                            sessionStorage.getItem('userIdToken') ||
                          caseSummary?.caseState.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                          !(userGroup && userGroup?.includes('CaseManager'))
                        }
                      >
                        QA Rework Required
                      </Button>
                    </Popconfirm>
                  </div>
                  <br />
                  <br />
                  <Button
                    style={{
                      minWidth: '100%',
                      margin: '5px 0',
                    }}
                    size="small"
                    disabled={
                      caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                      caseSummary?.caseState.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                      !(userGroup && userGroup?.includes('CaseManager'))
                    }
                    onClick={() => setShowOverrideModal(true)}
                    icon={<WarningOutlined />}
                  >
                    Override to {caseSummary.output === 'Rejected' ? 'Approve' : 'Reject'}
                  </Button>
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div
                style={{
                  minWidth: '100%',
                  padding: '10px',
                  margin: '5px',
                  backgroundColor: '#fafafa',
                }}
              >
                QA Actions: <br />
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onQaRejectClick()}
                  disabled={
                    caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                    caseSummary?.caseState?.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                    !(userGroup && userGroup?.includes('CaseManager'))
                  }
                >
                  <Button
                    style={{
                      minWidth: '100%',
                      margin: '5px 0',
                      backgroundColor: '#f59382',
                    }}
                    size="small"
                    loading={QARejectLoading}
                    disabled={
                      caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                      caseSummary?.caseState?.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                      !(userGroup && userGroup?.includes('CaseManager'))
                    }
                  >
                    Reject Application
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onQaApproveClick()}
                  disabled={
                    caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                    caseSummary?.caseState?.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                    !(userGroup && userGroup?.includes('CaseManager'))
                  }
                >
                  <Button
                    style={{
                      minWidth: '100%',
                      margin: '5px 0',
                      backgroundColor: '#82f584',
                    }}
                    size="small"
                    loading={QAApproveLoading}
                    disabled={
                      caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                      caseSummary?.caseState?.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                      !(userGroup && userGroup?.includes('CaseManager'))
                    }
                  >
                    Approve Application
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onQaReworkRequiredClick()}
                  disabled={
                    caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                    caseSummary?.caseState?.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                    !(userGroup && userGroup?.includes('CaseManager'))
                  }
                >
                  <Button
                    style={{
                      minWidth: '100%',
                      margin: '5px 0',
                      backgroundColor: '#f3f582',
                    }}
                    size="small"
                    loading={QAReworkRequiredLoading}
                    disabled={
                      caseSummary?.qaReviewAssigneeId !== sessionStorage.getItem('userIdToken') ||
                      caseSummary?.caseState?.toUpperCase() !== 'QAREVIEW'.toUpperCase() ||
                      !(userGroup && userGroup?.includes('CaseManager'))
                    }
                  >
                    QA Rework Required
                  </Button>
                </Popconfirm>
              </div>
            </>
          )}

          {/*  TODO: FinanceActions Component should only show if logged in user is in Pathways Finance group */}
          <FinanceActions.Component
            disabled={
              !(
                userGroup &&
                userGroup?.includes('Finance') &&
                caseSummary?.caseState?.toUpperCase() === 'APPLICATIONCOMPLETED'
              )
            }
            usmleId={caseSummary?.usmleId}
            dataVersion={dataVersion}
            updateDataVersion={(version: string) => updateDataVersion(version)}
          />
          {caseSummary?.eligibilityReviewAssigneeId === sessionStorage.getItem('userIdToken') &&
          caseSummary?.caseState?.toUpperCase() === 'APPLICATIONCOMPLETED' &&
          userGroup &&
          userGroup?.includes('CaseManager') && caseSummary.output?.toUpperCase() === "REJECTED" ? (
            <CaseOverviewActionResetApplication.Component caseSummary={caseSummary} />
          ) : null}
        </Col>
      </Row>

      <Modal
        title={`Override Decision`}
        visible={showOverrideModal}
        onCancel={() => setShowOverrideModal(false)}
        footer={[
          <Button form="qaOverrideForm" htmlType="submit" loading={QAOverrideDecisionLoading}>
            Ok
          </Button>,
        ]}
      >
        <Form
          id={'qaOverrideForm'}
          size={'small'}
          form={form}
          onFinish={() => onQaOverride()}
          initialValues={{}}
        >
          Please explain reasoning for overriding the decision:
          <Form.Item
            name="overrideComment"
            rules={[{ required: true, message: 'Please enter a reason for override' }]}
            initialValue={overrideComments}
          >
            <TextArea
              rows={4}
              value={overrideComments}
              onChange={(event) => {
                setOverrideComments(event.target.value);
              }}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`Confirm Pathway Override`}
        visible={showConfirmPathwayModal}
        closable={false}
        onCancel={() => {
          setShowConfirmPathwayModal(false);
          onCloseConfirmPathwayModal();
        }}
        destroyOnClose
        footer={[
          <Button type="primary" onClick={() => overridePathway()}>
            Confirm
          </Button>,
          <Button
            onClick={() => {
              setShowConfirmPathwayModal(false);
              onCloseConfirmPathwayModal();
            }}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row>
          <Col>{`USMLE ID: ${caseSummary?.usmleId}`}</Col>
        </Row>
        <Row>
          <Col>{'Enter Applicant USMLE ID to confirm:'}</Col>
        </Row>
        <Row>
          <Col>
            <Input
              type="text"
              value={confirmUSMLEIDText}
              onChange={(e) => setConfirmUSMLEIDText(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default {
  Component: CaseOverviewActions,
};
