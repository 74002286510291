import { Row, Col, Divider, Descriptions } from 'antd';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC } from 'react';
import { LicenseCardList } from '../physician-license-card-list';
import { getPhysicianName, PATHWAY6_DATE_FORMAT } from '../../../../../constants';
import moment from 'moment';
import { PhysicianLicenseRecordType } from '../../../../../../generated';

const Pathway6ReviewPhysicianDetailsPropTypes = {
  currentRecord: PropTypes.shape({
    dataVersion: PropTypes.number.isRequired,
    encounterId: PropTypes.string.isRequired,
    physicianEmail: PropTypes.string.isRequired,
    physicianLastNameProvidedByApplicant: PropTypes.string.isRequired,
    physicianRestOfNameProvidedByApplicant: PropTypes.string.isRequired,
    physicianLastNameProvidedByPhysician: PropTypes.string.isRequired,
    physicianRestOfNameProvidedByPhysician: PropTypes.string.isRequired,
    usmleId: PropTypes.string.isRequired,
    applicantLastName: PropTypes.string.isRequired,
    applicantRestOfName: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    expiresAt: PropTypes.string.isRequired,
    completedAt: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    stateModel: PropTypes.arrayOf(
      PropTypes.shape({
        state: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
      })
    ),
    allowedActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isActive: PropTypes.bool.isRequired,
  }),
  currentPhysicianData: PropTypes.shape({
    physicianId: PropTypes.string.isRequired,
    dataVersion: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    minicex: PropTypes.shape({
      accountStatus: PropTypes.string.isRequired,
    }),
    profile: PropTypes.shape({
      restOfName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      licenses: PropTypes.arrayOf(
        PropTypes.shape({
          physicianRegAuthorityRef: PropTypes.number.isRequired,
          orgName: PropTypes.string.isRequired,
          countryName: PropTypes.string.isRequired,
          licenseNumber: PropTypes.string.isRequired,
          addedAt: PropTypes.string.isRequired,
          removedAt: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
};
export type Pathway6ReviewPhysicianDetailsProps = InferProps<
  typeof Pathway6ReviewPhysicianDetailsPropTypes
>;
const Pathway6ReviewPhysicianDetails: FC<Pathway6ReviewPhysicianDetailsProps> = ({
  currentRecord,
  currentPhysicianData,
}) => {
  return (
    <>
      <div style={{ border: '2px solid', padding: '5px' }}>
        <Descriptions layout="vertical" column={4} size="small">
          <Descriptions.Item label="Applicant">{`${currentRecord?.applicantLastName}, ${currentRecord?.applicantRestOfName}`}</Descriptions.Item>
          <Descriptions.Item label="USMLEID">{currentRecord?.usmleId}</Descriptions.Item>
          <Descriptions.Item label="Encounter ID">{currentRecord?.encounterId}</Descriptions.Item>
          <Descriptions.Item label="Date Requested">
            {currentRecord?.createdAt !== undefined
              ? moment(currentRecord?.createdAt).utc().format(PATHWAY6_DATE_FORMAT)
              : ''}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Divider orientation="left">
        <b>Physician Details</b>
      </Divider>

      <Descriptions layout="vertical" column={4} size="small">
        <Descriptions.Item label="Name from Physician">
          {getPhysicianName(
            currentPhysicianData?.profile?.lastName,
            currentPhysicianData?.profile?.restOfName
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Name from Applicant">
          {getPhysicianName(
            currentRecord?.physicianLastNameProvidedByApplicant,
            currentRecord?.physicianRestOfNameProvidedByApplicant
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Primary E-mail Address">
          {currentRecord?.physicianEmail}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {currentPhysicianData?.minicex?.accountStatus}
        </Descriptions.Item>
      </Descriptions>
      <Row>
        <Col style={{ fontSize: 14 }}>
          <b>Licensure Information:</b>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LicenseCardList
            licenses={currentPhysicianData?.profile?.licenses as PhysicianLicenseRecordType[]}
            isEditable={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default Pathway6ReviewPhysicianDetails;
