import { FC, useState, useEffect } from 'react';
import { Divider, Space, Button, message, Modal, Spin } from 'antd';
import QualificationReviewException from '../qualification-review-exception';
import QualificationReviewCSExam2 from '../qualification-review-csExam-not-recent-past';
import QualificationReviewLanguageAssessment from '../qualification-review-languageAssessment';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import SummaryPDFViewer from '../../../../SummaryPDFViewer';
import { useFeatureFlags } from '../../../../feature-flag-react-lite';
import moment from 'moment';
import QualificationReviewPathway6 from '../qualification-review-pathway6';
import { getSummarySectionIndication, SummarySectionsIndication } from '../../../../constants';
import { case_ } from '../../../../schema/casePortal-case';
import {
  CaseMgmtApplicantSummaryCasePortalDocument,
  CaseMgmtApplicantSummarySubmissionSummaryDocument,
  CaseSummaryType,
  Pathway345,
} from '../../../../../generated';
import QualificationReviewCsExamV6 from '../qualification-review-csExamV6';
import QualificationReviewEducationV6 from '../qualification-review-educationV6';
import QualificationReviewLicenseV6 from '../qualification-review-licenseV6';

const PATHWAY_NAME = {
  PathwayX: 'PATHWAYX',
  Pathway1: 'PATHWAY1',
  Pathway2: 'PATHWAY2',
  Pathway3: 'PATHWAY3',
  Pathway4: 'PATHWAY4',
  Pathway5: 'PATHWAY5',
  Pathway6: 'PATHWAY6',
};

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const ApplicantSummaryV6: FC<any> = (props: any) => {
  const [showSummaryPDf, setShowSummaryPDF] = useState<boolean>(false);
  const [summaryPDFName, setSummaryPDFName] = useState<string | undefined>(undefined);
  const [summaryPDFRetryCount, setSummaryPDFRetryCount] = useState<number>(0);
  const { loading, error, data } = useQuery(CaseMgmtApplicantSummaryCasePortalDocument, {
    variables: { usmleId: props.usmleID },
  });
  const [getApplicantSummaryPDF] = useMutation(CaseMgmtApplicantSummarySubmissionSummaryDocument);
  const { GetFeatureFlagByName } = useFeatureFlags();
  const [summarySectionIndication, setSummarySectionIndication] =
    useState<SummarySectionsIndication | undefined>(undefined);
  const [isEmswpPDFVisible, setIsEmswpPDFVisible] = useState(false);

  useEffect(() => {
    if (data) {
      let isStep2CSFailed = data?.casePortal?.case?.applicant?.isStep2CSFailed;
      let schoolPathway =
        data?.casePortal?.case?.applicant?.graduation?.schoolEligibility?.schoolPathway;
      let isYearEligible = data?.casePortal?.case?.applicant?.graduation?.isYearEligible;
      setSummarySectionIndication(
        getSummarySectionIndication(isStep2CSFailed, schoolPathway, isYearEligible)
      );
    }
  }, [data]);
  if (error) {
    console.log('ERROR FETCHING DATA', error);
    return <div>{'ErrorX :( --> (applicant-summary)'}</div>;
  }

  // const getAttestation = () => {
  //   if (data?.casePortal?.case?.pathway345?.applicantProvidedData) {
  //     if (data?.casePortal?.case?.pathway345?.applicantProvidedData.length > 0) {
  //       if (data?.casePortal?.case?.pathway345?.applicantProvidedData[0]?.attestation) {
  //         if (
  //           data?.casePortal?.case?.pathway345?.applicantProvidedData[0]?.attestation.status ===
  //           'Completed'
  //         ) {
  //           return (
  //             <Button onClick={() => setIsEmswpPDFVisible(true)}>
  //               View Clinical Skills Attestation
  //             </Button>
  //           );
  //         }
  //       }
  //     }
  //   }
  // };

  const GetPathwaySection = (submittedPathway: string) => {
    if (submittedPathway) {
      //exception
      if (submittedPathway === PATHWAY_NAME.PathwayX) {
        return (
          <QualificationReviewException.Component pathwayX={data?.casePortal?.case?.pathwayX} />
        );
      }
      //license - pathway1
      if (submittedPathway === PATHWAY_NAME.Pathway1) {
        return (
          <QualificationReviewLicenseV6.Component pathway1={data?.casePortal?.case?.pathway1} />
        );
      }
      //cs exam - pathway2
      if (submittedPathway === PATHWAY_NAME.Pathway2) {
        return (
          <>
            <QualificationReviewLicenseV6.Component pathway1={data?.casePortal?.case?.pathway1} />
            <QualificationReviewCsExamV6.Component pathway2={data?.casePortal?.case?.pathway2} />
          </>
        );
      }
      //education - path345
      if (
        submittedPathway === PATHWAY_NAME.Pathway3 ||
        submittedPathway === PATHWAY_NAME.Pathway4 ||
        submittedPathway === PATHWAY_NAME.Pathway5
      ) {
        return (
          <>
            <QualificationReviewLicenseV6.Component pathway1={data?.casePortal?.case?.pathway1} />
            <QualificationReviewCSExam2.Component pathway2={data?.casePortal?.case?.pathway2} />
            <QualificationReviewEducationV6.Component
              pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
              caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
            />
          </>
        );
      }

      //pathway 6
      if (submittedPathway === PATHWAY_NAME.Pathway6) {
        if (summarySectionIndication === SummarySectionsIndication.Pw6) {
          return <QualificationReviewPathway6.Component />;
        }

        return (
          <>
            <QualificationReviewLicenseV6.Component pathway1={data?.casePortal?.case?.pathway1} />
            <QualificationReviewCSExam2.Component pathway2={data?.casePortal?.case?.pathway2} />
            {summarySectionIndication === SummarySectionsIndication.Pw12_2b_345_6 ? (
              <>
                <Divider plain orientation="left">
                  <Space size="middle">
                    <b>Pathway 3, 4, or 5</b>
                  </Space>
                </Divider>
                <div>I do not meet the requirements for Pathway 3, 4, or 5.</div>
              </>
            ) : null}

            <QualificationReviewPathway6.Component />
          </>
        );
      }
    } else {
      if (data?.casePortal?.case?.pathwayX) {
        return (
          <QualificationReviewException.Component pathwayX={data?.casePortal?.case?.pathwayX} />
        );
      } else {
        return (
          <>
            <QualificationReviewLicenseV6.Component pathway1={data?.casePortal?.case?.pathway1} />

            <QualificationReviewCsExamV6.Component pathway2={data?.casePortal?.case?.pathway2} />

            <QualificationReviewEducationV6.Component
              pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
              caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
            />
          </>
        );
      }
    }
  };

  const applicantSummaryPDF = async (usmleId: string) => {
    setShowSummaryPDF(true);
    await getApplicantSummaryPDF({
      variables: {
        usmleId: usmleId,
      },
    })
      .then((data) => {
        console.log('CasePortal_SubmissionSummary' + data?.data?.CasePortal_SubmissionSummary!);
        setSummaryPDFName(data?.data?.CasePortal_SubmissionSummary!);
      })
      .catch((error) => {
        if (error?.graphQLErrors[0]?.extensions?.code === 'RETRY' && summaryPDFRetryCount < 3) {
          setSummaryPDFRetryCount(summaryPDFRetryCount + 1);

          setTimeout(
            () => applicantSummaryPDF(usmleId),
            parseInt(process.env.REACT_APP_SUBMISSION_SUMMARY_RETRY_DELAY ?? '10')
          );
        } else {
          //only grab the first error
          const graphQLErrorReference = error?.graphQLErrors[0]?.extensions?.referenceId || '';
          message.error(`${error.toString()} - Reference ID : ${graphQLErrorReference}`);
        }
      });
  };

  const viewPdfClosed = async () => {
    setShowSummaryPDF(false);
    setSummaryPDFName(undefined);
  };

  // const GetEmswpAttestationPDF = () => {
  //   var blobData = pdf(
  //     <EMSWPAttestationPDFV1
  //       caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
  //       pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
  //     />
  //   ).toBlob();
  //   return (
  //     <SummaryPDFViewer
  //       filename={`Clinical Skills Attestation - ${props.usmleID}`}
  //       docId=""
  //       blobPromise={blobData}
  //     ></SummaryPDFViewer>
  //   );
  // };

  if (loading) {
    return <Spin></Spin>;
  }
  return (
    <div>
      {GetFeatureFlagByName('SubmissionSummary') === 'true' &&
      !!data?.casePortal?.case?.caseSummary?.caseState &&
      data?.casePortal?.case?.caseSummary?.caseState !== 'ApplicationCreated' ? (
        moment(data.casePortal.case.caseSummary.submittedAt) >
        moment(process.env.REACT_APP_SUBMISSION_SUMMARY_CUTOFF_DATE ?? '') ? (
          <Button onClick={(usmleId) => applicantSummaryPDF(props.usmleID)}>
            Application Summary
          </Button>
        ) : null
      ) : null}
      {GetPathwaySection(data?.casePortal?.case?.caseSummary?.submittedPathway!)}
      {/* languageAssessment */}
      {!!data?.casePortal?.case?.languageAssessment ? (
        <QualificationReviewLanguageAssessment.Component
          languageAssessment={data.casePortal.case.languageAssessment}
        />
      ) : null}

      <Modal
        title={document?.title}
        visible={showSummaryPDf}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw', maxHeight:'80vh' }}
        okText="Continue"
        footer={null}
        onCancel={() => viewPdfClosed()}
      >
        {summaryPDFName ? (
          <>
            {
              <SummaryPDFViewer
                filename={`Application Summary - ${props.usmleID}`}
                docId={summaryPDFName}
              ></SummaryPDFViewer>
            }
          </>
        ) : (
          <Spin />
        )}
      </Modal>

      {/* <Modal
        title={'Clinical Skills Attestation'}
        visible={isEmswpPDFVisible}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw' }}
        okText="Continue"
        footer={null}
        onCancel={() => setIsEmswpPDFVisible(false)}
      >
        {/* <PDFViewer height="100%" width="100%">
          <EMSWPAttestationPDFV1
            caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
            pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
          />
        </PDFViewer> }
        <GetEmswpAttestationPDF />
      </Modal> */}
    </div>
  );
};

export default ApplicantSummaryV6;
