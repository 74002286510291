import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col, Divider, message, Spin, Modal, Button } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { UploadDocument } from '../../upload-document/upload-document';
import _ from 'lodash';
import { case_ } from '../../../../schema/casePortal-case';
import {
  ActivityLogType,
  CaseMgmtPathway3DocumentReviewLayoutAddAndDeleteDocumentDocument,
  CaseSummaryType,
  CastMgmtPathway3DocumentReviewLayoutCasePortalDocument,
  C_ApplicantType,
  Pathway345,
  UiPortalConfigType,
} from '../../../../../generated';
import SummaryPDFViewer from '../../../../SummaryPDFViewer';
import EMSWPAttestationPDFV1 from './emswp-attestation-pdf-versions/emswp-attestation-pdf-v1';
import { pdf } from '@react-pdf/renderer';
import { EMSWPAttestationPDFFactory } from './emswp-attestation-pdf-factory';

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const Pathway3EMSWPAttestationLayout: FC<any> = ({ casePortal }) => {
  const [isEmswpPDFVisible, setIsEmswpPDFVisible] = useState(false);

  const getAttestation = () => {
    if (casePortal?.case?.pathway345?.applicantProvidedData) {
      if (casePortal?.case?.pathway345?.applicantProvidedData.length > 0) {
        if (casePortal?.case?.pathway345?.applicantProvidedData[0]?.attestation) {
          if (
            casePortal?.case?.pathway345?.applicantProvidedData[0]?.attestation.status ===
            'Completed'
          ) {
            return (
              <>
                <Divider plain orientation="center">
                  <b>Attestation Summary</b>
                </Divider>
                <Button onClick={() => setIsEmswpPDFVisible(true)}>
                  View Clinical Skills Attestation
                </Button>
              </>
            );
          }
        }
      }
    }
  };

  const GetEmswpAttestationPDF = () => {
    var blobData = pdf(
      <EMSWPAttestationPDFFactory
        versionConfig={
          casePortal?.case?.uiConfig as UiPortalConfigType
        }
        caseSummary={casePortal?.case?.caseSummary as CaseSummaryType}
        pathway345={casePortal?.case?.pathway345 as Pathway345}
      />
    ).toBlob();

    return (
      <SummaryPDFViewer
        filename={`Clinical Skills Attestation - ${casePortal?.case?.caseSummary?.usmleId}`}
        docId=""
        blobPromise={blobData}
      ></SummaryPDFViewer>
    );
  };

  return (
    <>
      {getAttestation()}

      <Modal
        title={'Clinical Skills Attestation'}
        visible={isEmswpPDFVisible}
        width="fit-content"
        closable
        bodyStyle={{ minWidth: '20vw' }}
        okText="Continue"
        footer={null}
        onCancel={() => setIsEmswpPDFVisible(false)}
      >
        {/* <PDFViewer height="100%" width="100%">
  <EMSWPAttestationPDFV1
    caseSummary={data?.casePortal?.case?.caseSummary as CaseSummaryType}
    pathway345={data?.casePortal?.case?.pathway345 as Pathway345}
  />
</PDFViewer> */}
        <GetEmswpAttestationPDF />
      </Modal>
    </>
  );
};

export default Pathway3EMSWPAttestationLayout;
