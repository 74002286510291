import React, { FC, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
  ArrowRightOutlined,
  ContainerOutlined,
  DollarOutlined,
  MedicineBoxOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { GetUserGroup, GetUserName } from '../../utils/helperFunctions';
import { type } from 'os';

const { Sider } = Layout;

const indentation0 = 15;
const indentation1 = 30;
const indentation2 = 45;
export interface NavProps {
  CaseDetailData: {
    casePortal: {
      case: {
        caseSummary: {
          submittedPathway: string;
          currentPathway: string;
        };
        pathway1: {
          _id: string;
          applicantProvidedData: {
            _id: string;
          }[];
          staffProvidedData: {
            _id: string;
          }[];
        };
        pathway2: {
          _id: string;
          applicantProvidedData: {
            _id: string;
          }[];
          staffProvidedData: {
            _id: string;
          }[];
        };
        pathway345: {
          _id: string;
          applicantProvidedData: {
            _id: string;
          }[];
          staffProvidedData: {
            _id: string;
          }[];
        };
        pathway6: {
          _id: string;
          applicantProvidedData: {
            _id: string;
          }[];
          staffProvidedData: {
            _id: string;
          }[];
        };
        pathwayX: {
          _id: string;
          data: {
            documents: {
              docId: string;
              docType: string;
              title: string;
            }[];
          };
        };
        languageAssessment: {
          applicantProvidedData: {
            _id: string;
            data: {
              documents: {
                docId: string;
                docType: string;
                title: string;
              }[];
            };
            review: {
              checklistState: string;
              checklist: {
                nameValid: string;
                scoreConfirmed: string;
                scoreAcceptable: string;
                examDateValidation: string;
              };
            };
          }[];
          staffProvidedData: {
            _id: string;
            data: {
              documents: {
                docId: string;
                docType: string;
                uploadedBy: {
                  name: string;
                };
              }[];
            };
            review: {
              checklistState: string;
              checklist: {
                nameValid: string;
                scoreConfirmed: string;
                scoreAcceptable: string;
                examDateValidation: string;
              };
            };
          }[];
        };
      };
    };
  };
}

const LeftNavStructure: FC<NavProps | any> = (props) => {
  const [caseMenuItems, setCaseMenuItems] = useState<any>(undefined);
  const location = useLocation();
  console.log('Prups', props);
  var caseDetailsData = props.CaseDetailData;
  const userGroup = GetUserGroup();

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);

  const addPathway1MenuItem = (index: any, id: any) => {
    return {
      id: index,
      key: `case-pathway-1-document-review-${id}`,
      icon: () => {
        return (
          <>
            <ArrowRightOutlined />
          </>
        );
      },
      content: () => {
        return (
          <Link to={`/case-management/case-detail/${caseIdParam}/pathway-1/document-review/${id}`}>
            License {id}
          </Link>
        );
      },
      indentation: indentation2,
    };
  };

  const addPathway2MenuItem = (index: any, id: any) => {
    console.log('pathway2');
    return {
      id: index,
      key: 'case-pathway-2',
      icon: () => {
        return (
          <>
            <ArrowRightOutlined />
          </>
        );
      },
      content: () => {
        return (
          <Link to={`/case-management/case-detail/${caseIdParam}/pathway-2/${id}`}>Pathway 2</Link>
        );
      },
      indentation: indentation1,
    };
  };

  const addPathway345MenuItem = (index: any, id: any, pathway: number) => {
    return {
      id: index,
      key: `case-pathway-${pathway}`,
      icon: () => {
        return (
          <>
            <ArrowRightOutlined />
          </>
        );
      },
      content: () => {
        return (
          <Link to={`/case-management/case-detail/${caseIdParam}/pathway-${pathway}/${id}`}>
            Pathway {pathway}
          </Link>
        );
      },
      indentation: indentation1,
    };
  };

  const addPathway6MenuItem = (index: any, id?: any) => {
    return {
      id: index,
      key: `case-pathway-6`,
      icon: () => {
        return (
          <>
            <ArrowRightOutlined />
          </>
        );
      },
      content: () => {
        return (
          <Link to={`/case-management/case-detail/${caseIdParam}/pathway-6/${id}`}>Pathway 6</Link>
        );
      },
      indentation: indentation1,
    };
  };

  const addPathwayXMenuItem = (index: any, id: any) => {
    return {
      id: index,
      key: 'case-pathway-X',
      icon: () => {
        return (
          <>
            <ArrowRightOutlined />
          </>
        );
      },
      content: () => {
        return (
          <Link to={`/case-management/case-detail/${caseIdParam}/pathway-X/${id}`}>Pathway X</Link>
        );
      },
      indentation: indentation1,
    };
  };

  let caseListMatch = useMatch({ path: '/case-management' });
  let caseDetailMatch = useMatch(`/case-management/case-detail/:caseId/*`);
  let caseIdParam =
    typeof caseDetailMatch?.params !== undefined
      ? (caseDetailMatch?.params as any)?.caseId ?? ''
      : '';

  var currentMenu = '';
  currentMenu = caseListMatch ? '/case-management' : 'case-detail';

  currentMenu = useMatch(`/case-management/case-detail/:caseId/*`) ? 'case-detail' : currentMenu;
  console.log(`current menu ${currentMenu}`);
  var pathWayMatch = useMatch(`/case-management/case-detail/:caseId/:pathway/*`);
  currentMenu = pathWayMatch ? `case-${(pathWayMatch?.params as any)?.pathway}` : currentMenu;
  console.log(`current menu ${currentMenu}`);
  var docReviewMatch = useMatch(
    `/case-management/case-detail/:caseId/pathway-1/document-review/:documentId/*`
  );
  currentMenu = docReviewMatch
    ? `case-pathway-1-document-review-${(docReviewMatch?.params as any)?.documentId ?? ''}`
    : currentMenu;
  console.log(`current menu ${currentMenu}`);

  if (caseDetailMatch) {
    if (caseDetailsData && caseDetailsData.casePortal?.case?.caseSummary && !caseMenuItems) {
      //Assumption - currentPath and SubmittedPath should always be available
      if (
        caseDetailsData.casePortal.case.caseSummary.submittedPathway &&
        caseDetailsData.casePortal.case.caseSummary.currentPathway
      ) {
        const submittedPath = caseDetailsData.casePortal.case.caseSummary.submittedPathway;
        const currentPath = caseDetailsData.casePortal.case.caseSummary.currentPathway;

        const initialCaseMenuItems: MenuItemProps[] = [
          {
            id: 2,
            type: 'divider',
          },
          {
            id: 3,
            type: 'item',
            key: 'case-detail',
            icon: () => {
              return <RightOutlined />;
            },
            content: () => {
              return (
                <Link to={`/case-management/case-detail/${caseIdParam}`}>
                  <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    Case Id: {caseIdParam}{' '}
                  </span>
                </Link>
              );
            },
            indentation: indentation0,
          },
        ];

        var menuCaseItemIndex = 4;
        if (submittedPath === currentPath || (submittedPath === null && currentPath === null)) {
          //  "load applicant provided data & staff provided data for currentPath"

          var pathwayData = undefined;

          if (currentPath.toUpperCase() === 'PATHWAY1') {
            initialCaseMenuItems.push({
              id: menuCaseItemIndex,
              key: 'case-pathway-1',
              icon: () => {
                return (
                  <>
                    <ArrowRightOutlined />
                  </>
                );
              },
              content: () => {
                return (
                  <Link to={`/case-management/case-detail/${caseIdParam}/pathway-1`}>
                    Pathway 1
                  </Link>
                );
              },
              indentation: indentation1,
            });
            menuCaseItemIndex++;
            pathwayData = caseDetailsData.casePortal.case.pathway1;
            //concatenate  applicant and staff data
            const pathwayProvidedData = pathwayData.applicantProvidedData.concat(
              pathwayData.staffProvidedData
            );
            console.log(pathwayProvidedData);
            pathwayProvidedData.map((license: any) => {
              initialCaseMenuItems.push(addPathway1MenuItem(menuCaseItemIndex, license._id));
              menuCaseItemIndex++;
            });
            //
          } else if (currentPath.toUpperCase() === 'PATHWAY2') {
            console.log('PTWY@2');
            pathwayData = caseDetailsData.casePortal.case.pathway2;
            const pathwayProvidedData = pathwayData.applicantProvidedData.concat(
              pathwayData.staffProvidedData
            );
            pathwayProvidedData.map((license: any) => {
              initialCaseMenuItems.push(addPathway2MenuItem(menuCaseItemIndex, license._id));
              menuCaseItemIndex++;
            });
          } else if (currentPath.toUpperCase() === 'PATHWAY3') {
            pathwayData = caseDetailsData.casePortal.case.pathway345;
            const pathwayProvidedData = pathwayData.applicantProvidedData.concat(
              pathwayData.staffProvidedData
            );
            pathwayProvidedData.map((license: any) => {
              initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, license._id, 3));
              menuCaseItemIndex++;
            });
          } else if (currentPath.toUpperCase() === 'PATHWAY4') {
            pathwayData = caseDetailsData.casePortal.case.pathway345;
            const pathwayProvidedData = pathwayData.applicantProvidedData.concat(
              pathwayData.staffProvidedData
            );
            pathwayProvidedData.map((license: any) => {
              initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, license._id, 4));
              menuCaseItemIndex++;
            });
          } else if (currentPath.toUpperCase() === 'PATHWAY5') {
            pathwayData = caseDetailsData.casePortal.case.pathway345;
            const pathwayProvidedData = pathwayData.applicantProvidedData.concat(
              pathwayData.staffProvidedData
            );
            pathwayProvidedData.map((license: any) => {
              initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, license._id, 5));
              menuCaseItemIndex++;
            });
          } else if (currentPath.toUpperCase() === 'PATHWAY6') {
            pathwayData = caseDetailsData.casePortal.case.pathway6;
            const pathwayProvidedData = pathwayData?.applicantProvidedData.concat(
              pathwayData.staffProvidedData
            );
            pathwayProvidedData?.map((license: any) => {
              initialCaseMenuItems.push(addPathway6MenuItem(menuCaseItemIndex, license._id));
              menuCaseItemIndex++;
            });
          } else if (currentPath.toUpperCase() === 'PATHWAYX') {
            //PATHWAYX can only be same current and submitted
            pathwayData = caseDetailsData.casePortal.case.pathwayX;

            initialCaseMenuItems.push(addPathwayXMenuItem(menuCaseItemIndex, pathwayData._id));
            menuCaseItemIndex++;
          }
        } else {
          //"load staff provided data for currentPath"
          if (currentPath.toUpperCase() === 'PATHWAY1') {
            initialCaseMenuItems.push({
              id: menuCaseItemIndex,
              key: 'case-pathway-1',
              icon: () => {
                return (
                  <>
                    <ArrowRightOutlined />
                  </>
                );
              },
              content: () => {
                return (
                  <Link to={`/case-management/case-detail/${caseIdParam}/pathway-1`}>
                    Pathway 1
                  </Link>
                );
              },
              indentation: indentation1,
            });
            menuCaseItemIndex++;
            const pathwayProvidedData = caseDetailsData.casePortal.case.pathway1.staffProvidedData;

            console.log(pathwayProvidedData);
            pathwayProvidedData.map((license: any) => {
              initialCaseMenuItems.push(addPathway1MenuItem(menuCaseItemIndex, license._id));
              menuCaseItemIndex++;
            });
          } else if (currentPath.toUpperCase() === 'PATHWAY2') {
            pathwayData = caseDetailsData.casePortal.case.pathway2.staffProvidedData;

            if (pathwayData.length === 0) {
              initialCaseMenuItems.push(addPathway2MenuItem(menuCaseItemIndex, 'OveridePath'));
            } else {
              pathwayData.map((license: any) => {
                initialCaseMenuItems.push(addPathway2MenuItem(menuCaseItemIndex, license._id));
              });
            }
            menuCaseItemIndex++;
          } else if (currentPath.toUpperCase() === 'PATHWAY3') {
            pathwayData = caseDetailsData.casePortal.case.pathway345.staffProvidedData;
            if (pathwayData?.length === 0) {
              initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, 'OveridePath', 3));
            } else {
              pathwayData.map((license: any) => {
                initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, license._id, 3));
              });
            }
            menuCaseItemIndex++;
          } else if (currentPath.toUpperCase() === 'PATHWAY4') {
            pathwayData = caseDetailsData.casePortal.case.pathway345.staffProvidedData;
            if (pathwayData?.length === 0) {
              initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, 'OveridePath', 4));
            } else {
              pathwayData.map((license: any) => {
                initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, license._id, 4));
              });
            }
            menuCaseItemIndex++;
          } else if (currentPath.toUpperCase() === 'PATHWAY5') {
            pathwayData = caseDetailsData.casePortal.case.pathway345.staffProvidedData;
            if (pathwayData?.length === 0) {
              initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, 'OveridePath', 5));
            } else {
              pathwayData.map((license: any) => {
                initialCaseMenuItems.push(addPathway345MenuItem(menuCaseItemIndex, license._id, 5));
              });
            }
            menuCaseItemIndex++;
          } else if (currentPath.toUpperCase() === 'PATHWAY6') {
            pathwayData = caseDetailsData.casePortal.case.pathway6.staffProvidedData;
            if (pathwayData?.length === 0) {
              initialCaseMenuItems.push(addPathway6MenuItem(menuCaseItemIndex, 'OveridePath'));
            } else {
              pathwayData.map((license: any) => {
                initialCaseMenuItems.push(addPathway6MenuItem(menuCaseItemIndex, 6));
              });
            }
            menuCaseItemIndex++;
          }
        }
        //add oet
        pathwayData = caseDetailsData?.casePortal?.case?.languageAssessment?.applicantProvidedData;
        if (pathwayData) {
          pathwayData.map((license: any) => {
            initialCaseMenuItems.push({
              id: menuCaseItemIndex,
              key: 'case-oet',
              icon: () => {
                return (
                  <>
                    <ArrowRightOutlined />
                  </>
                );
              },
              content: () => {
                return (
                  <Link to={`/case-management/case-detail/${caseIdParam}/oet/${license._id}`}>
                    OET Review
                  </Link>
                );
              },
              indentation: indentation1,
            });

            menuCaseItemIndex++;
          });
        }
        console.log(initialCaseMenuItems);

        setCaseMenuItems(initialCaseMenuItems);
        //initialCaseMenuItems.push(addOETMenuItem(menuCaseItemIndex, ))
      }
    }
  }
  //return <> </>

  interface MenuItemProps {
    id: number;
    type?: string;
    key?: string;
    indentation?: number;
    disabled?: boolean;
    icon?: () => void;
    content?: () => void;
  }

  const menuItems: MenuItemProps[] = [
    {
      id: 1,
      type: 'item',
      key: '/case-management',
      icon: () => {
        return <ContainerOutlined />;
      },
      content: () => {
        return <Link to={'/case-management'}>Pathway Case List</Link>;
      },
    },
    {
      id: 102,
      key: '/physician-management',
      icon: () => {
        return <MedicineBoxOutlined />;
      },
      content: () => {
        return <Link to={'/physician-management'}>Physician List</Link>;
      },
    },
    {
      id: 999,
      type: 'divider',
    },
    {
      id: 1000,
      key: 'username',
      icon: () => {
        return <ContainerOutlined style={{ visibility: 'hidden' }} />;
      },
      content: () => {
        return <div style={{ color: '#3f4373' }}>Logged in as </div>;
      },
      disabled: true,
    }, 
    {
      id: 1001,
      key: 'username2',
      icon: () => {
        return <ContainerOutlined style={{ visibility: 'hidden' }} />;
      },
      content: () => {
        return <div style={{ color: '#000' }}>{GetUserName()}</div>;
      },
      disabled: true,
    },
  ] as any;

  if (userGroup?.includes('Finance')) {
    menuItems.push(
      {
        id: 100,
        key: '/finance-settings',
        icon: () => {
          return <DollarOutlined />;
        },
        content: () => {
          return <Link to={'/case-management/finance-settings'}>Finance Settings</Link>;
        },
      },
      {
        id: 101,
        type: 'divider',
      }
    );
  }

  const fullMenu = menuItems
    .concat(caseIdParam === '' ? [] : caseMenuItems ? caseMenuItems : [])
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1));

  useEffect(() => {
    if (!isExpanded) {
      const icons = document.querySelectorAll('.ant-menu-item.sider-icon');
      icons.forEach((icon) => icon.setAttribute('style', 'white-space: normal'));
    }

    // const caseListMenuItem = document.querySelector('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:first-child')
    // console.log("CASE LIST ", caseListMenuItem)
    // const siderMenuItem = document.querySelectorAll('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item.sider-icon')
    // console.log("SIDER MENU ", siderMenuItem)

    // let itemAlreadySelected = false
    // for (let i = 1; i < siderMenuItem.length; i++) {
    //   if (siderMenuItem[i].classList.contains('ant-menu-item-selected')) {
    //     itemAlreadySelected = true
    //   }
    // }
    // if (!itemAlreadySelected) {
    //   caseListMenuItem?.classList.add('ant-menu-item-selected')
    // }
  });

  const handleToggler = () => {
    const icons = document.querySelectorAll('.ant-menu-item.sider-icon');
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem('sidebar-collapsed', 'true');
      icons.forEach((icon) => icon.setAttribute('style', 'white-space: normal'));
      return;
    }
    setIsExpanded(true);
    localStorage.removeItem('sidebar-collapsed');
    icons.forEach((icon) => {
      icon.setAttribute('style', 'white-space: nowrap');
      fixThePadding(icon);
    });
  };

  const fixThePadding = (icon: any) => {
    const id = parseInt(icon.getAttribute('itemID'));
    if (id === null) {
      return;
    }
    const item = fullMenu.find((item) => item.id === id);
    if (item == null) {
      return;
    }
    const indentation = item.indentation + 'px';
    icon.setAttribute('style', `padding-left: ${indentation}`);
  };

  return (
    <Sider
      //width={200}
      className="site-layout-background"
      collapsible
      collapsed={!isExpanded}
      onCollapse={handleToggler}
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 64px)',
        position: 'relative',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#fff',
      }}
    >
      <Menu
        theme="light"
        mode="inline"
        forceSubMenuRender={true}
        defaultSelectedKeys={[currentMenu]}
        selectedKeys={[currentMenu]}
        style={{ height: '100%', borderRight: 0 }}
      >
        {fullMenu &&
          fullMenu.map((item: any) => {
            if (item.type === 'divider') {
              return <Menu.Divider />;
            } else {
              return (
                <Menu.Item
                  icon={item.icon()}
                  key={item.key}
                  itemID={item.id}
                  disabled={item.disabled ? item.disabled : false}
                  className="sider-icon"
                  style={{ paddingLeft: item.indentation }}
                >
                  {item.content()}
                </Menu.Item>
              );
            }
          })}
      </Menu>
    </Sider>
  );
};

export default LeftNavStructure;
