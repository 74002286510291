import React, { FC, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  Alert,
  Space,
  Button,
  Row,
  Col,
  Modal,
  Form,
  InputNumber,
  Popconfirm,
  Checkbox,
  Spin,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GetUserName, GetUserGroup } from '../../utils/helperFunctions';
import { case_ } from '../schema/casePortal-case';
import {
  CaseMgmtFinanceActionsCasePortalDocument,
  CaseMgmtFinanceActionsIssuePaperCheckDocument,
  CaseMgmtFinanceActionsRequestRefundDocument,
  RequestPayload,
} from '../../generated';

export interface ComponentProp {
  casePortal: {
    case: case_;
  };
}

const financeActionsPropsTypes = {
  disabled: PropTypes.bool.isRequired,
  usmleId: PropTypes.string.isRequired,
  dataVersion: PropTypes.number.isRequired,
  updateDataVersion: PropTypes.func.isRequired,
};

export type FinanceActionProps = InferProps<typeof financeActionsPropsTypes>;

const FinanceActions: FC<FinanceActionProps | any> = ({
  disabled,
  usmleId,
  dataVersion,
  updateDataVersion,
}) => {

  const userGroup = GetUserGroup();
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [refundHasFailed, setRefundHasFailed] = useState<boolean>(false);
  const [refundAmount, setRefundAmount] = useState(''); //The refund they will recieve: String
  const [showRefundErrorAlert, setShowRefundErrorAlert] = useState<boolean>(false);
  const [showRefundSuccessAlert, setShowRefundSuccessAlert] = useState<boolean>(false);
  const [refundAlertMessage, setRefundAlertMessage] = useState<RequestPayload['responseMessage']>(
    ''
  );
  const [paymentAmount, setPaymentAmount] = useState(0); //What they paid originally: Int
  const [issuePaperCheckChecked, setIssuePaperCheckChecked] = useState<boolean>(false);
  const [paperCheckErrorTextHidden, setPaperCheckErrorTextHidden] = useState<boolean>(true);
  const [userName, setUserName] = useState('');
  const { loading, error, data } = useQuery(CaseMgmtFinanceActionsCasePortalDocument, {
    variables: { usmleId: usmleId, isFinanceUser: userGroup?.includes('Finance') },
  });
  const [getRefund, { loading: getRefundLoading }] = useMutation(
    CaseMgmtFinanceActionsRequestRefundDocument
  );
  const [issuePaperCheck, { loading: issuePaperCheckLoading }] = useMutation(
    CaseMgmtFinanceActionsIssuePaperCheckDocument
  );

  if (error) {
    console.log('FinanceActions ==> ERROR: ', error);
  }

  const requestRefund = async () => {
    //Reset alerts
    setShowRefundErrorAlert(false);
    setShowRefundSuccessAlert(false);

    var results = await getRefund({
      variables: {
        caseWorker: userName,
        usmleID: usmleId,
        amount: refundAmount,
      },
    });
    setShowRefundModal(!showRefundModal);
    if (
      (results?.data?.requestRefund?.result !== '0' &&
        results?.data?.requestRefund?.responseMessage !== undefined) ||
      results?.data?.requestRefund?.result === 'FAIL'
    ) {
      setRefundAlertMessage(results?.data?.requestRefund?.responseMessage);
      setShowRefundErrorAlert(true);
      setRefundHasFailed(true);
    }
    if (results?.data?.requestRefund === null) {
      setRefundAlertMessage('Issue contacting Paypal');
      setShowRefundErrorAlert(true);
      setRefundHasFailed(true);
    } else if (results?.data?.requestRefund?.result === '0') {
      setRefundAlertMessage('Refund Successful');
      setShowRefundSuccessAlert(true);
    }
  };

  const toggleShowRefundModal = () => {
    const paymentAmt = data?.casePortal?.case?.finance?.payment?.amt;

    if (typeof paymentAmt === 'number') {
      setPaymentAmount(paymentAmt);
      if (refundAmount === '') {
        setRefundAmount(paymentAmt?.toString());
      }
    }

    setUserName(GetUserName());
    setShowRefundModal(!showRefundModal);
  };

  const refundOnChange = (value: any) => {
    setRefundAmount(value.toString());
  };

  const savePaperCheck = async () => {
    //paperCheckErrorTextHidden
    setShowRefundSuccessAlert(false);
    setShowRefundErrorAlert(false);
    if (issuePaperCheckChecked) {
      setPaperCheckErrorTextHidden(true);

      await issuePaperCheck({
        variables: {
          usmleId: usmleId,
          amount: parseInt(refundAmount),
        },
      })
        .then((result) => {
          setRefundHasFailed(false);
          setShowRefundModal(!showRefundModal);
          if (result?.data?.CasePortal_issuePaperCheck?.code === 'PASS') {
            setRefundAlertMessage('Success issuing paper check');
            setShowRefundSuccessAlert(true);
          } else if (result?.data?.CasePortal_issuePaperCheck?.code === 'FAIL') {
            setRefundAlertMessage(result?.data?.CasePortal_issuePaperCheck?.msg);
            setShowRefundErrorAlert(true);
          } else {
            setRefundAlertMessage('There was an issue processing paper check refund');
            setShowRefundErrorAlert(true);
          }
          //CODE BELOW NEEDS TO GET IMPLEMENTED IN THE ENHANCEMENT PERIOD
          //updateDataVersion(result?.data?.CasePortal_issuePaperCheck?.dataVersion);
        })
        .catch((error) => {
          setShowRefundModal(!showRefundModal);
          console.log(error);
          setRefundAlertMessage('There was an issue contacting the database');
          setShowRefundErrorAlert(true);
        });
    } else {
      setPaperCheckErrorTextHidden(false);
    }
  };

  const onChangeIssuePaperCheckCheckbox = (checked: any) => {
    if (checked) {
      setPaperCheckErrorTextHidden(true);
    }
    setIssuePaperCheckChecked(checked);
  };

  if(loading) {
    return <Spin></Spin>
  }
  
  return (
    <>
      {showRefundErrorAlert ? (
        <div>
          <Alert message={refundAlertMessage} type="error" closable showIcon></Alert>
        </div>
      ) : (
        <div></div>
      )}
      {showRefundSuccessAlert ? (
        <div>
          <Alert message={refundAlertMessage} type="success" closable showIcon></Alert>
        </div>
      ) : (
        <div></div>
      )}
      <div
        style={{
          minWidth: '100%',
          padding: '10px',
          margin: '5px',
          backgroundColor: '#fafafa',
        }}
      >
        Finance Actions: <br />
        <Button
          onClick={(e) => toggleShowRefundModal()}
          style={{
            minWidth: '100%',
            margin: '5px 0',
            backgroundColor: '#f3f582',
          }}
          size="small"
          disabled={disabled}
        >
          Issue Refund
        </Button>
      </div>
      <Modal
        title="Issue Refund"
        visible={showRefundModal}
        footer={null}
        onCancel={() => toggleShowRefundModal()}
      >
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item label="Initial Payment">{`$${paymentAmount}`}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Amount to Refund">
                <InputNumber
                  defaultValue={paymentAmount}
                  parser={(value) =>
                    typeof value !== 'undefined' ? +value.replace(/\$\s?|(,*)/g, '') : +''
                  }
                  onChange={refundOnChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Space direction={'horizontal'}>
                  <Button onClick={(e) => toggleShowRefundModal()}>Cancel</Button>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={(e) => requestRefund()}
                  >
                    <Button loading={getRefundLoading}>Refund</Button>
                  </Popconfirm>
                </Space>
              </div>
            </Col>
          </Row>
          {refundHasFailed ? (
            <>
              <Row>
                <Col span={24}>
                  <hr style={{ borderTop: '1px black solid', margin: '25px 0' }} />
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <Checkbox
                    value={issuePaperCheckChecked}
                    onChange={(e) => onChangeIssuePaperCheckCheckbox(e.target.checked)}
                  >
                    Mark record as paper check sent
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Button onClick={(e) => savePaperCheck()} loading={issuePaperCheckLoading}>
                    Save Change
                  </Button>
                </Col>
                <Col>
                  <label hidden={paperCheckErrorTextHidden} style={{ color: 'red' }}>
                    Please check paper check checkbox
                  </label>
                </Col>
              </Row>
            </>
          ) : (
            <div></div>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default {
  Component: FinanceActions,
};
